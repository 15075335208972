import { useState, useEffect } from 'react';

interface TabMetadata {
  id: string;
  label: string;
  isPinned: boolean;
}

const DEFAULT_TABS: TabMetadata[] = [
  { id: 'verification', label: 'Verification Document', isPinned: true },
  { id: 'contacts', label: 'Contacts', isPinned: true },
  { id: 'rdds', label: 'RDDS', isPinned: true },
  { id: 'files', label: 'Files', isPinned: true },
  { id: 'bounces', label: 'Email Bounces', isPinned: true },
  { id: 'tasks', label: 'Tasks', isPinned: true },
  { id: 'databases', label: 'Databases', isPinned: false },
  { id: 'domains', label: 'Domains', isPinned: false },
  { id: 'partners', label: 'Partners', isPinned: false },
];

export function usePersistedTabs() {
  const [tabsMetadata, setTabsMetadata] = useState<TabMetadata[]>(() => {
    try {
      const stored = localStorage.getItem('deal-tabs-metadata');
      if (!stored)
        return DEFAULT_TABS;

      const parsed = JSON.parse(stored);

      // Validate that all default tabs exist in the stored data
      const hasAllTabs = DEFAULT_TABS.every((defaultTab) =>
        parsed.some((storedTab: TabMetadata) => storedTab.id === defaultTab.id),
      );

      // If stored data is missing any default tabs, use defaults
      return hasAllTabs ? parsed : DEFAULT_TABS;
    } catch {
      return DEFAULT_TABS;
    }
  });

  useEffect(() => {
    localStorage.setItem('deal-tabs-metadata', JSON.stringify(tabsMetadata));
  }, [tabsMetadata]);

  return {
    tabsMetadata,
    setTabsMetadata,
    resetToDefault: () => setTabsMetadata(DEFAULT_TABS),
  };
}

export type { TabMetadata };
