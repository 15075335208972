import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type {
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { format, parse, isValid } from 'date-fns';
import { GripHorizontal, ChevronRight, Plus, X, Loader2, Calendar, MessageSquare, Copy } from 'lucide-react';
import { toast } from 'sonner';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { VerificationDocument, type VerificationItem } from '@/components/verification-document';
import { DealProvider } from '@/contexts/deal-context';
import { useDeal, useUpdateDeal, type DealStage, type DealType, type DealOutcome } from '@/hooks/use-deals';
import { useUpdateDomains, useDomains, useCreateDomains, useDomainExists } from '@/hooks/use-domains';
import { usePersistedTabs, type TabMetadata } from '@/hooks/use-persisted-tabs';
import { cn } from '@/lib/utils';
import {
  ActivityPanel,
  BouncesPanel,
  ContactsPanel,
  DatabasesPanel,
  DomainsPanel,
  FilesPanel,
  PartnersPanel,
  RDDSPanel,
  TasksPanel,
} from './components';
import CommunicationsSheet from './components/communications-sheet';
import { DomainEditModal } from './components/domain-edit-modal';

const DEAL_OUTCOMES: DealOutcome[] = [
  'Approved',
  'Combined',
  'Consolidation',
  'Did not Register',
  'Domain Deleted',
  'Duplicate',
  'fTLD Domain',
  'Incomplete',
  'Ineligible',
  'Junk',
  'Name Selection',
  'No Renew',
  'No Reply',
  'No Verification Needed',
  'Not Wanted',
  'Organization',
  'Price',
  'Test',
];

const DEAL_TYPES: DealType[] = [
  'New Business',
  'Existing Business',
  'Annual Verification',
  'Forced Verification',
];

const DEAL_STAGES: DealStage[] = [
  'Annual Verification',
  'Pending Verification',
  'Organization Verification in Progress',
  'Name Selection in Progress',
  'Employment Verification in Progress',
  'WHOIS Update in Progress',
  'Failed Verification',
  'Verification Completed',
  'Verified Data Sent',
  'Completed',
];

interface TabConfig {
  id: string;
  label: string;
  content: React.ReactNode;
  isPinned: boolean;
}

interface SortableTabProps {
  id: string;
  tab: TabConfig;
  onTogglePin: (id: string) => void;
}

function SortableTab({ id, tab, onTogglePin }: SortableTabProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Card ref={setNodeRef} style={style} className="flex flex-col">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">
          {tab.label}
        </CardTitle>
        <div className="flex items-center gap-2">
          <button
            onClick={() => onTogglePin(id.replace('tab-content-', ''))}
            className="text-muted-foreground hover:text-foreground"
          >
            ×
          </button>
          <div {...attributes} {...listeners}>
            <GripHorizontal className="size-4 cursor-move text-muted-foreground" />
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex-1 p-0">
        {tab.content}
      </CardContent>
    </Card>
  );
}

interface SortableTabControlProps {
  id: string;
  label: string;
  onClose: () => void;
}

function SortableTabControl({ id, label, onClose }: SortableTabControlProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn(
        'flex items-center gap-2 rounded-md border bg-card px-3 py-1.5 text-sm shadow-sm',
        isDragging && 'opacity-50',
      )}
    >
      <div {...attributes} {...listeners}>
        <GripHorizontal className="size-3 cursor-move text-muted-foreground" />
      </div>
      <span>{label}</span>
      <button
        onClick={onClose}
        className="text-muted-foreground hover:text-foreground"
      >
        ×
      </button>
    </div>
  );
}

function LastVerifiedModal({
  open,
  onOpenChange,
  onSave,
  initialDate,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (date: string) => void;
  initialDate?: string;
}) {
  const [date, setDate] = useState<Date | undefined>(
    initialDate ? new Date(initialDate) : undefined,
  );

  const handleSave = () => {
    if (date) {
      onSave(date.toISOString());
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set Last Verified Date</DialogTitle>
          <DialogDescription>
            This will update the last verified date for all domains in this deal.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center gap-2 py-4">
          <Label className="text-sm">Date</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  'w-[120px] justify-start text-left font-normal',
                  !date && 'text-muted-foreground',
                )}
              >
                {date ? format(date, 'MM/dd/yyyy') : 'Pick a date'}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <CalendarComponent
                mode="single"
                selected={date}
                onSelect={setDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={!date}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function DealPage() {
  const { dealId } = useParams();
  const { setLabel, removeLabel } = useBreadcrumb();
  const { data: deal } = useDeal(dealId!);
  const { mutate: updateDeal, isPending: isUpdating } = useUpdateDeal();
  const { mutate: updateDomains, isPending: isUpdatingDomains } = useUpdateDomains();
  const { data: domains } = useDomains();
  const createDomains = useCreateDomains();
  const checkDomainExists = useDomainExists();
  const [dealType, setDealType] = useState<DealType>('New Business');
  const [outcome, setOutcome] = useState<DealOutcome | null>(null);
  const [stage, setStage] = useState<DealStage>('Annual Verification');
  const [openCommunications, setOpenCommunications] = useState(false);

  const { tabsMetadata, setTabsMetadata } = usePersistedTabs();

  // Initialize tabs with content based on metadata order and pinned state
  const [tabs, setTabs] = useState<TabConfig[]>(() => {
    return tabsMetadata.map((metadata) => ({
      id: metadata.id,
      label: metadata.label,
      isPinned: metadata.isPinned,
      content: (() => {
        switch (metadata.id) {
          case 'verification': {
            return (
              <div className="flex h-full items-center justify-center">
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Loader2 className="size-4 animate-spin" />
                  Loading verification document...
                </div>
              </div>
            );
          }
          case 'contacts': {
            return <ContactsPanel />;
          }
          case 'rdds': {
            return <RDDSPanel />;
          }
          case 'files': {
            return <FilesPanel />;
          }
          case 'bounces': {
            return <BouncesPanel />;
          }
          case 'tasks': {
            return <TasksPanel />;
          }
          case 'databases': {
            return <DatabasesPanel />;
          }
          case 'domains': {
            return <DomainsPanel />;
          }
          case 'partners': {
            return <PartnersPanel />;
          }
          default: {
            return null;
          }
        }
      })(),
    }));
  });

  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 8,
      },
    }),
  );
  const [isEditingDomains, setIsEditingDomains] = useState(false);
  const [isLastVerifiedModalOpen, setIsLastVerifiedModalOpen] = useState(false);
  const [processedDomains, setProcessedDomains] = useState<Set<string>>(new Set());

  const handleCopyDomains = async () => {
    if (deal?.domains) {
      try {
        await navigator.clipboard.writeText(deal.domains.join(', '));
        toast.success('Copied domains to clipboard');
      } catch {
        toast.error('Failed to copy domains');
      }
    }
  };

  useEffect(() => {
    if (!deal)
      return;

    const path = `/app/deals/${dealId}`;
    const domainsLabel = deal.domains.length > 2
      ? `${deal.domains.slice(0, 2).join(', ')} +${deal.domains.length - 2} more`
      : deal.domains.join(', ');
    const label = `${domainsLabel} - ${format(new Date(deal.created_at), 'MMM d')}`;
    setLabel(path, {
      name: label,
      path,
    });
    return () => removeLabel(path);
  }, [dealId, deal, setLabel, removeLabel]);

  const toggleTabPin = (tabId: string) => {
    setTabs(tabs.map((tab) => tab.id === tabId ? { ...tab, isPinned: !tab.isPinned } : tab));
    setTabsMetadata(tabsMetadata.map((tab) => tab.id === tabId ? { ...tab, isPinned: !tab.isPinned } : tab));
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over)
      return;

    if (active.id === over.id)
      return;

    const isTabControl = active.id.toString().startsWith('tab-control-');
    const isTabContent = active.id.toString().startsWith('tab-content-');

    const getTabId = (id: string) => {
      if (isTabControl)
        return id.replace('tab-control-', '');
      if (isTabContent)
        return id.replace('tab-content-', '');
      return id;
    };

    const activeId = getTabId(active.id.toString());
    const overId = getTabId(over.id.toString());

    setTabs((items) => {
      const oldIndex = items.findIndex((item) => item.id === activeId);
      const newIndex = items.findIndex((item) => item.id === overId);
      return arrayMove(items, oldIndex, newIndex);
    });

    setTabsMetadata((items: TabMetadata[]) => {
      const oldIndex = items.findIndex((item: TabMetadata) => item.id === activeId);
      const newIndex = items.findIndex((item: TabMetadata) => item.id === overId);
      return arrayMove(items, oldIndex, newIndex);
    });

    setActiveId(null);
  };

  const pinnedTabs = tabs.filter((tab) => tab.isPinned);
  const unpinnedTabs = tabs.filter((tab) => !tab.isPinned);

  useEffect(() => {
    if (deal?.deal_type) {
      setDealType(deal.deal_type);
    }
    if (deal?.outcome) {
      setOutcome(deal.outcome);
    }
    if (deal?.deal_stage) {
      setStage(deal.deal_stage);
    }
  }, [deal?.deal_type, deal?.outcome, deal?.deal_stage]);

  // Update verification document tab when deal changes
  useEffect(() => {
    setTabs((currentTabs) =>
      currentTabs.map((tab) =>
        tab.id === 'verification'
          ? {
              ...tab,
              content: deal && (
                <VerificationDocument
                  key={deal.id}
                  deal={deal}
                  onSave={(items: VerificationItem[]) => {
                    if (!dealId)
                      return;
                    updateDeal(
                      {
                        id: dealId,
                        verification_document: {
                          body: JSON.stringify(items),
                        },
                      },
                      {
                        onSuccess: () => {
                          toast.success('Verification document saved');
                        },
                        onError: (error) => {
                          toast.error(error.message);
                        },
                      },
                    );
                  }}
                />
              ),
            }
          : tab,
      ),
    );
  }, [deal, dealId, updateDeal]);

  const handleStageChange = (value: string) => {
    const newStage = value;
    setStage(newStage);
    if (dealId) {
      updateDeal(
        { id: dealId, deal_stage: newStage },
        {
          onSuccess: () => {
            toast.success('Deal status updated');
          },
          onError: (error: unknown) => {
            const errorMessage = error instanceof Error
              ? error.message
              : 'Failed to update deal status';
            toast.error(errorMessage);
            // Revert to previous stage on error
            setStage(deal?.deal_stage || 'Annual Verification');
          },
        },
      );
    }
  };

  const handleSaveDomains = (updatedDomains: string[]) => {
    if (!dealId)
      return;

    updateDeal(
      { id: dealId, domains: updatedDomains },
      {
        onSuccess: () => {
          toast.success('Domains updated');
        },
        onError: (error: unknown) => {
          const errorMessage = error instanceof Error
            ? error.message
            : 'Failed to update domains';
          toast.error(errorMessage);
        },
      },
    );
  };

  // Get the common last verified date if all domains in the deal have the same date
  const getCommonLastVerifiedDate = () => {
    if (!deal?.domains || !domains)
      return;

    const dealDomains = domains.filter((d) => deal.domains.includes(d.name));
    if (dealDomains.length === 0)
      return;

    const firstDate = dealDomains[0].last_verified;
    if (!firstDate)
      return;

    const allSameDate = dealDomains.every((d) => d.last_verified === firstDate);
    return allSameDate ? firstDate : undefined;
  };

  const handleLastVerifiedSave = (dateString: string) => {
    if (!deal?.domains)
      return;

    updateDomains(
      {
        domains: deal.domains,
        updateData: {
          last_verified: dateString,
        },
      },
      {
        onSuccess: () => {
          toast.success('Last verified date updated for all domains');
        },
        onError: (error) => {
          toast.error(error.message);
        },
      },
    );
  };

  // Add effect to check and create domains on deal load
  useEffect(() => {
    if (!deal?.domains || deal.domains.length === 0)
      return;

    // Check if we've already processed these exact domains
    const domainKey = deal.domains.sort().join(',');
    if (processedDomains.has(domainKey))
      return;

    const checkAndCreateDomains = async () => {
      const domainsToCreate: string[] = [];

      // Check each domain sequentially
      for (const domain of deal.domains) {
        try {
          const exists = await checkDomainExists.mutateAsync(domain);
          if (!exists) {
            domainsToCreate.push(domain);
          }
        } catch (error) {
          // If error is not a 404, log it but continue checking other domains
          console.error(`Error checking domain ${domain}:`, error);
        }
      }

      // Create all missing domains at once
      if (domainsToCreate.length > 0) {
        createDomains.mutate(domainsToCreate, {
          onSuccess: () => {
            toast.success(`Created ${domainsToCreate.length} missing domain(s)`);
          },
          onError: (error) => {
            const errorMessage = error instanceof Error ? error.message : 'Failed to create domains';
            toast.error(errorMessage);
          },
        });
      }

      // Mark these domains as processed
      setProcessedDomains((prev) => new Set([...prev, domainKey]));
    };

    void checkAndCreateDomains();
  }, [deal?.domains]); // We only want this to run when domains actually change, not on every render

  return (
    <DealProvider dealId={dealId}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Container maxWidth={false} className="flex h-[calc(100vh-4rem)] flex-col gap-2">
          <Card className="flex-none">
            <CardContent className="py-4">
              <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between gap-4">
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <div className="font-medium">
                        {deal?.domains && deal.domains.length > 2
                          ? `${deal.domains.slice(0, 2).join(', ')} +${deal.domains.length - 2} more`
                          : deal?.domains?.join(', ')}
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsEditingDomains(true)}
                        className="h-8"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={handleCopyDomains}
                        className="h-8"
                      >
                        <Copy className="mr-1" size={16} />
                      </Button>
                    </div>
                    {(isUpdating || isUpdatingDomains) && (
                      <div className="text-sm text-muted-foreground">
                        Saving changes...
                      </div>
                    )}
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => setIsLastVerifiedModalOpen(true)}
                        className="h-8 px-2"
                      >
                        {(() => {
                          const commonDate = getCommonLastVerifiedDate();
                          if (commonDate) {
                            return `Verified ${format(new Date(commonDate), 'MM/dd/yyyy')}`;
                          }
                          return 'Set Date';
                        })()}
                      </Button>
                    </div>
                    <Select
                      value={dealType}
                      onValueChange={(value) => {
                        const newType = value;
                        setDealType(newType);
                        if (dealId) {
                          updateDeal(
                            { id: dealId, deal_type: newType },
                            {
                              onSuccess: () => {
                                toast.success('Deal type updated');
                              },
                              onError: (error: unknown) => {
                                const errorMessage = error instanceof Error
                                  ? error.message
                                  : 'Failed to update deal type';
                                toast.error(errorMessage);
                                // Revert to previous type on error
                                setDealType(deal?.deal_type || 'New Business');
                              },
                            },
                          );
                        }
                      }}
                    >
                      <SelectTrigger className="h-8 w-[180px]">
                        <SelectValue placeholder="Select type" />
                      </SelectTrigger>
                      <SelectContent>
                        {DEAL_TYPES.map((type) => (
                          <SelectItem key={type} value={type}>
                            {type}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Select
                      value={outcome || '_none'}
                      onValueChange={(value) => {
                        const newOutcome = value === '_none' ? null : value;
                        setOutcome(newOutcome);
                        if (dealId) {
                          updateDeal(
                            { id: dealId, outcome: newOutcome },
                            {
                              onSuccess: () => {
                                toast.success('Deal outcome updated');
                              },
                              onError: (error: unknown) => {
                                const errorMessage = error instanceof Error
                                  ? error.message
                                  : 'Failed to update deal outcome';
                                toast.error(errorMessage);
                                // Revert to previous outcome on error
                                setOutcome(deal?.outcome || null);
                              },
                            },
                          );
                        }
                      }}
                    >
                      <SelectTrigger className="h-8 w-[180px]">
                        <SelectValue placeholder="Select outcome" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="_none">None</SelectItem>
                        {DEAL_OUTCOMES.map((dealOutcome) => (
                          <SelectItem key={dealOutcome} value={dealOutcome}>
                            {dealOutcome}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Select
                      value={stage}
                      onValueChange={(value) => handleStageChange(value)}
                    >
                      <SelectTrigger className="h-8 w-[180px]">
                        <SelectValue placeholder="Select stage" />
                      </SelectTrigger>
                      <SelectContent>
                        {DEAL_STAGES.map((dealStage) => (
                          <SelectItem key={dealStage} value={dealStage}>
                            {dealStage}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Button
                      variant="default"
                      size="sm"
                      onClick={() => setOpenCommunications(true)}
                      className="h-8"
                    >
                      <MessageSquare className="mr-2 size-4" />
                      Communications
                    </Button>
                  </div>
                </div>

                {/* Tab Controls */}
                <div className="flex items-center gap-2 border-y py-2">
                  <div className="no-scrollbar flex-1 overflow-x-auto">
                    <SortableContext
                      items={pinnedTabs.map((tab) => `tab-control-${tab.id}`)}
                      strategy={horizontalListSortingStrategy}
                    >
                      <div className="flex items-center gap-2">
                        {pinnedTabs.map((tab) => (
                          <SortableTabControl
                            key={tab.id}
                            id={`tab-control-${tab.id}`}
                            label={tab.label}
                            onClose={() => toggleTabPin(tab.id)}
                          />
                        ))}
                      </div>
                    </SortableContext>
                  </div>
                </div>
                {unpinnedTabs.length > 0 && (
                  <div className="flex items-center gap-2">
                    <ChevronRight className="size-4 text-muted-foreground" />
                    <div className="text-sm text-muted-foreground">Add panel:</div>
                    {unpinnedTabs.map((tab) => (
                      <button
                        key={tab.id}
                        onClick={() => toggleTabPin(tab.id)}
                        className="rounded-md px-3 py-1 text-sm hover:bg-muted"
                      >
                        {tab.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </CardContent>
          </Card>

          {/* Pinned Tabs - Horizontally Scrollable */}
          <div className="relative flex-1 overflow-hidden">
            <div className="absolute inset-0">
              <div className="h-full overflow-x-auto">
                <div className="flex h-full gap-4 pb-4">
                  <SortableContext
                    items={pinnedTabs.map((tab) => `tab-content-${tab.id}`)}
                    strategy={horizontalListSortingStrategy}
                  >
                    {pinnedTabs.map((tab) => (
                      <div
                        key={tab.id}
                        className={cn(
                          'h-full flex-none',
                          (() => {
                            if (tab.id === 'verification')
                              return 'w-[600px]';
                            if (tab.id === 'databases' || tab.id === 'domains')
                              return 'w-[550px]';
                            return 'w-[500px]';
                          })(),
                        )}
                      >
                        <SortableTab
                          id={`tab-content-${tab.id}`}
                          tab={tab}
                          onTogglePin={toggleTabPin}
                        />
                      </div>
                    ))}
                  </SortableContext>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <DragOverlay>
          {activeId && (
            <div className="rounded-md border bg-card px-3 py-1.5 text-sm shadow-sm">
              {tabs.find((t) => activeId.includes(t.id))?.label}
            </div>
          )}
        </DragOverlay>
      </DndContext>

      <CommunicationsSheet open={openCommunications} onOpenChange={setOpenCommunications} />
      <DomainEditModal
        open={isEditingDomains}
        onOpenChange={setIsEditingDomains}
        domains={deal?.domains || []}
        onSave={handleSaveDomains}
      />
      <LastVerifiedModal
        open={isLastVerifiedModalOpen}
        onOpenChange={setIsLastVerifiedModalOpen}
        onSave={handleLastVerifiedSave}
        initialDate={getCommonLastVerifiedDate()}
      />
    </DealProvider>
  );
}

export default DealPage;
