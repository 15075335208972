import * as React from 'react';
import { Separator } from '@/components/ui/separator';
import { useHub } from '@/contexts/hub-context';
import type { MessageDB } from '@/types/message';
import type { CommunicationDisplay } from '../types/communications';
import type { Message as NylasMessage } from 'nylas';
import { MessageAttachments } from './message-attachments';
import { MessageContent } from './message-content';
import { MessageHeader } from './message-header';

type MessageContainerProps = {
  communication: CommunicationDisplay;
  message?: MessageDB | NylasMessage;
  content: Partial<NylasMessage>;
  timestamp: string | Date;
};

export function MessageContainer({
  communication,
  message,
  content,
  timestamp,
}: MessageContainerProps) {
  const { isDealInbox } = useHub();

  // If we're in the deal inbox, we're using MessageDB
  // Otherwise, we're using NylasMessage
  if (isDealInbox) {
    const messageDB = message as MessageDB;
    return (
      <div>
        <MessageHeader
          sender={communication.sender}
          timestamp={timestamp}
          isExternal={communication.isExternal}
          isEmail={communication?.isEmail}
          emailThreadId={messageDB?.thread_id}
          emailMessageId={messageDB?.message_id}
          emailFrom={messageDB?.from}
          emailTo={messageDB?.to}
          emailCC={messageDB?.cc}
          emailBCC={messageDB?.bcc}
          emailSubject={messageDB?.content?.subject}
        />
        <Separator className="my-4" />
        <MessageContent content={content} />
        {communication.attachments && (
          <MessageAttachments attachments={communication.attachments} />
        )}
      </div>
    );
  }

  // We're in a grant inbox, so we're using NylasMessage
  return (
    <div>
      <MessageHeader
        sender={communication.sender}
        timestamp={timestamp}
        isExternal={communication.isExternal}
        isEmail={communication.isEmail}
        emailThreadId={content.threadId}
        emailMessageId={content.id}
        emailFrom={content?.from}
        emailTo={content?.to}
        emailCC={content?.cc}
        emailBCC={content?.bcc}
        emailSubject={content.subject}
      />
      <Separator className="my-4" />
      <MessageContent content={content} />
      {communication.attachments && (
        <MessageAttachments attachments={communication.attachments} />
      )}
    </div>
  );
}
