import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { X, GripVertical, Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import type { SortableItemProps, VerificationItem } from '../types';
import { AddItemDialog } from './add-item-dialog';
import { FdicCheckComponent } from './fdic-check';
import { OFACCheckComponent } from './ofac-check';
import { SignableNoteComponent } from './signable-note';

export function SortableItem({
  id,
  item,
  onVerifyOFAC,
  onVerifyFDIC,
  onUpdateNoteContent,
  onSignNote,
  onDelete,
  isSearching,
  userId,
  items,
  setItems,
  deal,
}: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const handleAddItem = (newItem: VerificationItem, position: 'above' | 'below') => {
    const currentIndex = items.findIndex((i) => i.id === id);
    const newItems = [...items];
    newItems.splice(position === 'above' ? currentIndex : currentIndex + 1, 0, newItem);
    setItems(newItems);
  };

  return (
    <div ref={setNodeRef} style={style} className="group relative pl-8">
      <TooltipProvider>
        <div className="absolute left-0 top-0">
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <AddItemDialog
                  onAddItem={(newItem) => handleAddItem(newItem, 'above')}
                  trigger={(
                    <Button
                      variant="ghost"
                      size="sm"
                      className="size-6 p-0 opacity-0 transition-opacity group-hover:opacity-100"
                    >
                      <Plus className="size-3" />
                    </Button>
                  )}
                />
              </div>
            </TooltipTrigger>
            <TooltipContent side="right">
              <p>Add Above</p>
            </TooltipContent>
          </Tooltip>
        </div>
        <div className="absolute bottom-0 left-0">
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <AddItemDialog
                  onAddItem={(newItem) => handleAddItem(newItem, 'below')}
                  trigger={(
                    <Button
                      variant="ghost"
                      size="sm"
                      className="size-6 p-0 opacity-0 transition-opacity group-hover:opacity-100"
                    >
                      <Plus className="size-3" />
                    </Button>
                  )}
                />
              </div>
            </TooltipTrigger>
            <TooltipContent side="right">
              <p>Add Below</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </TooltipProvider>
      <Card className="mb-4">
        <CardHeader className="flex flex-row items-center gap-4">
          <div {...attributes} {...listeners}>
            <GripVertical className="size-4 cursor-move text-muted-foreground" />
          </div>
          <div className="flex flex-1 items-center justify-between">
            <CardTitle className="text-base">
              {item.type === 'ofac'
                ? `OFAC Check - ${item.entityName || 'Unnamed Entity'}`
                : (item.type === 'fdic'
                    ? `FDIC Check - ${item.entityName || 'Unnamed Entity'}`
                    : item.title)}
            </CardTitle>
            <Button
              variant="ghost"
              size="sm"
              className="h-6 px-2 text-muted-foreground hover:text-destructive"
              onClick={() => onDelete(id)}
            >
              <X className="size-3" />
            </Button>
          </div>
        </CardHeader>

        <CardContent>
          {item.type === 'ofac'
            ? (
                <OFACCheckComponent
                  item={item}
                  onVerify={onVerifyOFAC}
                  onSign={onSignNote}
                  isSearching={isSearching}
                  items={items}
                  setItems={setItems}
                  userId={userId}
                />
              )
            : (item.type === 'fdic'
                ? (
                    <FdicCheckComponent
                      item={item}
                      onVerify={onVerifyFDIC}
                      onSign={onSignNote}
                      isSearching={isSearching}
                      items={items}
                      setItems={setItems}
                      userId={userId}
                    />
                  )
                : (
                    <SignableNoteComponent
                      item={item}
                      onUpdateContent={onUpdateNoteContent}
                      onSign={onSignNote}
                      userId={userId}
                      deal={deal}
                    />
                  ))}
        </CardContent>
      </Card>
    </div>
  );
}
