import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { Download } from 'lucide-react';
import { toast } from 'sonner';
import { utils, writeFile } from 'xlsx';
import * as z from 'zod';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useContactsByDomainStatusReport } from '@/hooks/use-reports';

const formSchema = z.object({
  domainStatus: z.array(z.string()).min(1, 'Select at least one domain status'),
  tld: z.array(z.string()).min(1, 'Select at least one TLD'),
  contactType: z.array(z.string()).min(1, 'Select at least one contact type'),
  reportName: z.string(),
});

type ContactsByDomainStatusFilters = z.infer<typeof formSchema>;

const DOMAIN_STATUSES = [
  { value: 'live', label: 'Live' },
  { value: 'redirect_in_zone', label: 'Redirect In Zone' },
  { value: 'redirect_out_of_zone', label: 'Redirect Out of Zone' },
  { value: 'parked', label: 'Parked' },
  { value: 'inactive', label: 'Inactive' },
];

const TLDS = [
  { value: '.Bank', label: '.Bank' },
  { value: '.Insurance', label: '.Insurance' },
];

const CONTACT_TYPES = [
  { value: 'registrant', label: 'Registrant' },
  { value: 'verification', label: 'Verification' },
];

function ContactsByDomainStatusReport() {
  const { setLabel, removeLabel } = useBreadcrumb();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isReportNameEdited, setIsReportNameEdited] = useState(false);
  const mountTime = useMemo(() => format(new Date(), 'HHmm-yyyy-MM-dd'), []);

  const form = useForm<ContactsByDomainStatusFilters>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      domainStatus: ['live', 'redirect_in_zone', 'redirect_out_of_zone'],
      tld: ['.Bank'],
      contactType: ['registrant', 'verification'],
      reportName: '',
    },
  });

  const watchedFilters = form.watch(['domainStatus', 'tld', 'contactType']);

  // Generate dynamic report name based on filters
  const generateReportName = (filters: [string[], string[], string[]]) => {
    const [domainStatus, tld, contactType] = filters;
    const parts = [
      domainStatus.length === DOMAIN_STATUSES.length ? 'all_statuses' : domainStatus.join('_'),
      tld.length === TLDS.length ? 'all_tlds' : tld.map((t) => t.replace('.', '')).join('_'),
      contactType.length === CONTACT_TYPES.length ? 'all_types' : contactType.join('_'),
      mountTime,
    ];
    return `contacts_${parts.join('_')}.xlsx`.toLowerCase();
  };

  // Update report name when filters change, unless manually edited
  useEffect(() => {
    if (!isReportNameEdited) {
      const newName = generateReportName(watchedFilters);
      form.setValue('reportName', newName);
    }
  }, [watchedFilters, form, isReportNameEdited, mountTime]);

  useEffect(() => {
    const path = '/app/reports/contacts-by-domain-status';
    setLabel(path, {
      name: 'Contacts by Domain Status',
      path,
    });
    return () => removeLabel(path);
  }, [setLabel, removeLabel]);

  const generateReport = useContactsByDomainStatusReport();

  const handleGenerateReport = async (data: ContactsByDomainStatusFilters) => {
    try {
      setIsGenerating(true);
      const response = await generateReport.mutateAsync({
        domainStatus: data.domainStatus,
        tld: data.tld,
        contactType: data.contactType,
      });

      // Create workbook and worksheet
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(response.data.contacts);

      // Add column headers
      utils.sheet_add_aoa(ws, [
        ['Name', 'Email', 'Phone', 'Organization'],
      ], { origin: 'A1' });

      // Auto-size columns
      let max_width = 10; // minimum width
      for (const contact of response.data.contacts) {
        if (contact.name.length > max_width) {
          max_width = contact.name.length;
        }
      }

      ws['!cols'] = [
        { wch: max_width }, // Name
        { wch: 30 }, // Email
        { wch: 15 }, // Phone
        { wch: 30 }, // Organization
      ];

      // Add the worksheet to the workbook
      utils.book_append_sheet(wb, ws, 'Contacts');

      // Generate and download the file
      writeFile(wb, data.reportName);

      toast.success('Report generated successfully');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Failed to generate report');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const handleActiveListPreset = () => {
    form.setValue('domainStatus', ['live', 'redirect_in_zone', 'redirect_out_of_zone']);
  };

  const handleInactiveListPreset = () => {
    form.setValue('domainStatus', ['parked', 'inactive']);
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-6">
        <h1 className="text-xl font-bold">Contacts by Domain Status</h1>
        <p className="text-sm text-muted-foreground">
          Generate a report of contacts filtered by domain status, TLD, and contact type.
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleGenerateReport)} className="space-y-8">
          <div className="space-y-2">
            <h2 className="text-sm font-medium">Frequently Used Filters</h2>
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={handleActiveListPreset}
              >
                Active List
              </Button>
              <Button
                type="button"
                variant="outline"
                onClick={handleInactiveListPreset}
              >
                Inactive List
              </Button>
            </div>
          </div>

          <div className="grid gap-6 md:grid-cols-3">
            <FormField
              control={form.control}
              name="domainStatus"
              render={() => (
                <FormItem>
                  <FormLabel>Domain Status</FormLabel>
                  <div className="space-y-2">
                    {DOMAIN_STATUSES.map((status) => (
                      <FormField
                        key={status.value}
                        control={form.control}
                        name="domainStatus"
                        render={({ field }) => (
                          <FormItem
                            key={status.value}
                            className="flex flex-row items-start space-x-3 space-y-0"
                          >
                            <FormControl>
                              <Checkbox
                                checked={field.value?.includes(status.value)}
                                onCheckedChange={(checked) => {
                                  const newValue = checked
                                    ? [...field.value, status.value]
                                    : field.value?.filter((value) => value !== status.value);
                                  field.onChange(newValue);
                                }}
                              />
                            </FormControl>
                            <FormLabel className="font-normal">
                              {status.label}
                            </FormLabel>
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                  <FormDescription>
                    Select one or more domain statuses to include in the report.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="tld"
              render={() => (
                <FormItem>
                  <FormLabel>TLD</FormLabel>
                  <div className="space-y-2">
                    {TLDS.map((tld) => (
                      <FormField
                        key={tld.value}
                        control={form.control}
                        name="tld"
                        render={({ field }) => (
                          <FormItem
                            key={tld.value}
                            className="flex flex-row items-start space-x-3 space-y-0"
                          >
                            <FormControl>
                              <Checkbox
                                checked={field.value?.includes(tld.value)}
                                onCheckedChange={(checked) => {
                                  const newValue = checked
                                    ? [...field.value, tld.value]
                                    : field.value?.filter((value) => value !== tld.value);
                                  field.onChange(newValue);
                                }}
                              />
                            </FormControl>
                            <FormLabel className="font-normal">
                              {tld.label}
                            </FormLabel>
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                  <FormDescription>
                    Select one or more TLDs to include in the report.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="contactType"
              render={() => (
                <FormItem>
                  <FormLabel>Contact Type</FormLabel>
                  <div className="space-y-2">
                    {CONTACT_TYPES.map((type) => (
                      <FormField
                        key={type.value}
                        control={form.control}
                        name="contactType"
                        render={({ field }) => (
                          <FormItem
                            key={type.value}
                            className="flex flex-row items-start space-x-3 space-y-0"
                          >
                            <FormControl>
                              <Checkbox
                                checked={field.value?.includes(type.value)}
                                onCheckedChange={(checked) => {
                                  const newValue = checked
                                    ? [...field.value, type.value]
                                    : field.value?.filter((value) => value !== type.value);
                                  field.onChange(newValue);
                                }}
                              />
                            </FormControl>
                            <FormLabel className="font-normal">
                              {type.label}
                            </FormLabel>
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                  <FormDescription>
                    Select one or more contact types to include in the report.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="max-w-3xl">
            <FormField
              control={form.control}
              name="reportName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Report Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setIsReportNameEdited(true);
                      }}
                    />
                  </FormControl>
                  <FormDescription>
                    The filename for the generated report. Updates automatically based on filters unless manually edited.
                  </FormDescription>
                </FormItem>
              )}
            />
          </div>

          <Button
            type="submit"
            disabled={isGenerating || !form.formState.isValid}
            className="gap-2"
          >
            <Download className="size-4" />
            {isGenerating ? 'Generating...' : 'Generate Report'}
          </Button>
        </form>
      </Form>
    </Container>
  );
}

export default ContactsByDomainStatusReport;
