import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/contacts';

export const CONTACTS_QUERY_KEY = ['contacts'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

export interface ContactLicense {
  id: string;
  authority: string;
  value: string;
}

export interface Contact {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  legal_name: string;
  email: string;
  phone_number: string;
  alt_phone: string | null;
  fax_number: string | null;
  address_line_1: string;
  address_line_2: string | null;
  address_line_3: string | null;
  city: string;
  state_province: string | null;
  postal_code: string;
  country: string;
  alt_name: string | null;
  alt_email: string | null;
  title: string | null;
  role_name: string | null;
  organization_legal_name: string | null;
  c_suite: boolean;
  sole_prop: boolean;
  new_legal_name: boolean;
  domain_contact_junctions: Array<{
    domain: string;
    type: 'registrant' | 'verification';
    created_at: string;
  }>;
  licenses: ContactLicense[];
}

interface CreateContactInput {
  legal_name: string;
  email: string;
  phone_number: string;
  alt_phone?: string | null;
  fax_number?: string | null;
  address_line_1: string;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city: string;
  state_province: string | null;
  postal_code: string;
  country: string;
  alt_name?: string | null;
  alt_email?: string | null;
  title?: string | null;
  role_name?: string | null;
  organization_legal_name?: string | null;
  c_suite?: boolean;
  sole_prop?: boolean;
  new_legal_name?: boolean;
  domain_associations?: Array<{
    domain: string;
    type: 'registrant' | 'verification';
  }>;
  licenses?: Array<{
    authority: string;
    value: string;
  }>;
}

export interface UpdateContactInput {
  legal_name?: string;
  email?: string;
  phone_number?: string;
  alt_phone?: string | null;
  fax_number?: string | null;
  address_line_1?: string;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city?: string;
  state_province?: string | null;
  postal_code?: string;
  country?: string;
  alt_name?: string | null;
  alt_email?: string | null;
  title?: string | null;
  role_name?: string | null;
  organization_legal_name?: string | null;
  c_suite?: boolean;
  sole_prop?: boolean;
  new_legal_name?: boolean;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// List all contacts
export function useContacts() {
  const axios = useAxios();

  return useQuery({
    queryKey: CONTACTS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<Contact[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Get a single contact by ID
export function useContact(id: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...CONTACTS_QUERY_KEY, id],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Contact>(`${API_BASE_URL}/${id}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!id,
  });
}

// Create a new contact
export function useCreateContact() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (input: CreateContactInput) => {
      try {
        const { data } = await axios.post<Contact>(API_BASE_URL, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY });
    },
  });
}

// Update a contact
export function useUpdateContact() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...input }: { id: string } & UpdateContactInput) => {
      try {
        const { data } = await axios.put<Contact>(`${API_BASE_URL}/${id}`, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, id] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Delete a contact
export function useDeleteContact() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      try {
        await axios.delete(`${API_BASE_URL}/${id}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, id) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, id] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Add a domain association
export function useAddDomainAssociation() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, domain, type }: { contactId: string; domain: string; type: 'registrant' | 'verification' }) => {
      try {
        await axios.post(`${API_BASE_URL}/${contactId}/domains`, { domain, type });
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Remove a domain association
export function useRemoveDomainAssociation() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, domain }: { contactId: string; domain: string }) => {
      try {
        await axios.delete(`${API_BASE_URL}/${contactId}/domains/${domain}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Update a domain association
export function useUpdateDomainAssociation() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, domain, type }: { contactId: string; domain: string; type: 'registrant' | 'verification' }) => {
      try {
        await axios.put(`${API_BASE_URL}/${contactId}/domains/${domain}`, { type });
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Get contact's domain associations
export function useContactDomains(contactId: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...CONTACTS_QUERY_KEY, contactId, 'domains'],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Array<{ domain: string; type: 'registrant' | 'verification'; created_at: string }>>(`${API_BASE_URL}/${contactId}/domains`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!contactId,
  });
}

// Add a license
export function useAddLicense() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, authority, value }: { contactId: string; authority: string; value: string }) => {
      try {
        const { data } = await axios.post(`${API_BASE_URL}/${contactId}/licenses`, { authority, value });
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Remove a license
export function useRemoveLicense() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, licenseId }: { contactId: string; licenseId: string }) => {
      try {
        await axios.delete(`${API_BASE_URL}/${contactId}/licenses/${licenseId}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Update a license
export function useUpdateLicense() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      contactId,
      licenseId,
      authority,
      value,
    }: {
      contactId: string;
      licenseId: string;
      authority: string;
      value: string;
    }) => {
      try {
        const { data } = await axios.put(
          `${API_BASE_URL}/${contactId}/licenses/${licenseId}`,
          { authority, value },
        );
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}
