/**
 * MessageInput is the main component for composing messages and emails.
 * It orchestrates multiple subcomponents to provide a complete message composition experience:
 * - EmailFields: Handles email-specific fields (To, CC, Subject)
 * - TiptapEditor: Rich text editor for message content
 * - TemplateDialog: Template selection and insertion
 * - MessageToolbar: Actions like emoji, attachments, and send
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { Content, Editor } from '@tiptap/react';
import { toast } from 'sonner';
import type { EmailChipsInputHandle } from '@/components/email-recipient-input';
import { TiptapEditor } from '@/components/tiptap';
import { useDeal } from '@/contexts/deal-context';
import { useHub } from '@/contexts/hub-context';
import { useEmailTemplates, useRenderEmailTemplate } from '@/hooks/use-email-templates';
import { useUserTemplates } from '@/hooks/use-user-templates';
import { convertToEmailHtml } from '@/lib/email';
import { VERIFY_EMAIL } from '@/lib/globals';
import { parseParticipants } from '@/lib/threads';
import { getLastItem } from '@/lib/utils';
import type { FormValues, MessageInputProps } from './types';
import { EmailFields } from './email-fields';
import { MessageToolbar } from './message-toolbar';
import { TemplateDialog } from './template-dialog';

/**
 * Main message composition component that coordinates all messaging functionality
 * Features:
 * - Email mode with recipient management
 * - Rich text editing
 * - Template support
 * - File attachments
 * - Reply handling
 */
export function MessageInput({ onSend, onUploadFiles }: MessageInputProps) {
  const {
    replyThread,
    replyMessage,
    setReplyThread,
    myGrantEmail,
    verifyUserId,
    sendAsGrantId,
    sendAsEmailAddress,
    replyKey,
  } = useHub();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isEmailMode, setIsEmailMode] = useState(true);
  const [showCc, setShowCc] = useState(true);
  const [showBcc, setShowBcc] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const { template } = useUserTemplates({
    name: 'personal-footer',
    userId: sendAsEmailAddress === VERIFY_EMAIL ? verifyUserId : undefined,
  });
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [editorContent, setEditorContent] = useState<Content>('');
  const { dealId } = useDeal();
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const editorRef = useRef<Editor | null>(null);
  const emailInputRef = useRef<EmailChipsInputHandle>(null);
  const ccInputRef = useRef<EmailChipsInputHandle>(null);
  const bccInputRef = useRef<EmailChipsInputHandle>(null);

  const { data: templates } = useEmailTemplates();

  const { control, watch } = useForm<FormValues>({
    defaultValues: {
      recipients: [],
      ccRecipients: [],
      bccRecipients: [],
    },
  });

  // Watch recipient fields for validation and template rendering
  const recipients = watch('recipients');
  const ccRecipients = watch('ccRecipients');
  const bccRecipients = watch('bccRecipients');

  const {
    data: renderedTemplate,
    isLoading: isRenderingTemplate,
    error: renderError,
  } = useRenderEmailTemplate(selectedTemplate ?? '', dealId ?? '', recipients);

  // Enable email mode and set subject when replying to a thread or message
  useEffect(() => {
    if (replyThread || replyMessage) {
      setIsEmailMode(true);
      const subject = replyMessage?.content.subject || replyThread?.subject || '';
      setEmailSubject(subject.toLowerCase().startsWith('re:') ? subject : `Re: ${subject}`);
    }
  }, [replyThread, replyMessage]);

  // Populate email addresses from reply thread or message after a small delay
  // to ensure components are mounted
  useEffect(() => {
    console.log(`populate`)
    if (replyThread && isEmailMode && replyThread.participants) {
      const timer = setTimeout(() => {
        emailInputRef.current?.clear();
        ccInputRef.current?.clear();
        bccInputRef.current?.clear();

        const messageToEmailAddresses = parseParticipants([
          ...(replyMessage?.from ?? []),
          ...(replyMessage?.to ?? []),
        ])
          .map((participant) => participant.email)
          .filter((email): email is string => Boolean(email) && email !== myGrantEmail);

        const messageCCEmailAddresses = parseParticipants(replyMessage?.cc ?? [])
          .map((participant) => participant.email)
          .filter((email): email is string => Boolean(email) && email !== myGrantEmail);

        const messageBccEmailAddresses = parseParticipants(replyMessage?.bcc ?? [])
          .map((participant) => participant.email)
          .filter((email): email is string => Boolean(email) && email !== myGrantEmail);

        const threadEmailAddresses = parseParticipants(replyThread.participants)
          .map((participant) => participant.email)
          .filter((email): email is string => Boolean(email) && email !== myGrantEmail);

        if (emailInputRef.current) {
          if (!messageToEmailAddresses && !messageCCEmailAddresses) {
            for (const email of threadEmailAddresses) {
              emailInputRef.current?.addEmail(email);
            }
          } else {
            for (const email of messageToEmailAddresses) {
              emailInputRef.current?.addEmail(email);
            }

            if (ccInputRef.current) {
              for (const email of messageCCEmailAddresses) {
                ccInputRef.current?.addEmail(email);
              }
            }

            if (bccInputRef.current) {
              for (const email of messageBccEmailAddresses) {
                bccInputRef.current?.addEmail(email);
              }
            }
          }
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [replyThread, replyMessage, isEmailMode, myGrantEmail, replyKey]);

  // Handle sending the message, including email-specific data when in email mode
  const handleSend = useCallback(() => {
    if (!editorContent)
      return;

    const html = typeof editorContent === 'string' ? editorContent : editorContent.toString();
    const emailHtml = convertToEmailHtml(html);
    const content = {
      html: emailHtml,
      isEmail: isEmailMode,
      ...(isEmailMode && {
        to: recipients,
        cc: ccRecipients,
        bcc: bccRecipients,
        subject: emailSubject,
        threadGrantId: replyThread?.grant_id,
        replyToMessageId: getLastItem(replyThread?.message_ids),
        sendAsGrantId,
      }),
    };

    onSend(content);

    if (isEmailMode && replyThread?.message_ids) {
      setReplyThread(undefined, undefined);
    }

    setEditorContent('');
    if (editorRef.current) {
      editorRef.current.commands.clearContent();
    }

    if (isEmailMode) {
      emailInputRef.current?.clear();
      ccInputRef.current?.clear();
      bccInputRef.current?.clear();
      setEmailSubject('');
    }
  }, [
    editorContent,
    onSend,
    setReplyThread,
    replyThread,
    isEmailMode,
    recipients,
    ccRecipients,
    bccRecipients,
    emailSubject,
    sendAsGrantId,
  ]);

  // Handle file attachment selection and upload
  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = [...e.target.files || []];
      if (files.length > 0 && onUploadFiles) {
        onUploadFiles(files);
        e.target.value = '';
      }
    },
    [onUploadFiles],
  );

  // Insert selected emoji at cursor position in editor
  const insertEmoji = useCallback((emoji: any) => {
    if (editorRef.current) {
      editorRef.current.commands.insertContent(emoji.native);
    }
    setShowEmojiPicker(false);
  }, []);

  // Handle template selection and trigger template rendering
  const handleTemplateSelect = useCallback((template: { name: string }) => {
    setShowTemplateDialog(false);
    setSelectedTemplate(template.name);
  }, []);

  // Update editor content and email fields when template renders successfully
  useEffect(() => {
    if (renderedTemplate && editorRef.current) {
      editorRef.current.commands.setContent(renderedTemplate.html);
      setEditorContent(renderedTemplate.html);

      if (isEmailMode) {
        setEmailSubject(renderedTemplate.subject);
        if (renderedTemplate.to) {
          for (const email of renderedTemplate.to) {
            emailInputRef.current?.addEmail(email);
          }
        }
      }

      setSelectedTemplate(undefined);
    }
  }, [renderedTemplate, isEmailMode]);

  // Display error message if template rendering fails and reset selection
  useEffect(() => {
    if (renderError) {
      toast.error('Failed to render template', {
        description: renderError.message,
      });
      setSelectedTemplate(undefined);
    }
  }, [renderError]);

  // Insert special character at cursor position in editor
  const handleSpecialCharacter = useCallback((char: string) => {
    if (editorRef.current) {
      editorRef.current.commands.insertContent(char);
    }
  }, []);

  // Insert user's signature at cursor position in editor
  const handleSignatureInsert = useCallback((userSignature: string) => {
    if (editorRef.current) {
      editorRef.current.commands.insertContent(userSignature);
    }
  }, []);

  // Store reference to editor instance for direct manipulation
  const handleEditorChange = useCallback((editor: Editor) => {
    editorRef.current = editor;
  }, []);

  // Determine if send button should be enabled based on content and mode
  const isSendEnabled = useCallback((): boolean => {
    if (!editorContent)
      return false;
    if (!isEmailMode)
      return true;
    if (!emailSubject)
      return false;

    return Boolean(recipients && recipients.length > 0);
  }, [editorContent, isEmailMode, emailSubject, recipients]);

  return (
    <div className="bg-background">
      {isEmailMode && (
        <EmailFields
          control={control}
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
          showCc={showCc}
          setShowCc={setShowCc}
          emailInputRef={emailInputRef}
          ccInputRef={ccInputRef}
          bccInputRef={bccInputRef}
          showBcc={showBcc}
          setShowBcc={setShowBcc}
        />
      )}

      <div className="rounded-md border bg-background">
        <TiptapEditor
          value={editorContent}
          onChange={setEditorContent}
          placeholder="Type a message..."
          className="min-h-[100px]"
          hideSections={['one', 'tasks']}
          disableAutolink
          editorContentClassName="prose prose-sm max-w-none focus:outline-none min-h-[40px] max-h-[300px] overflow-y-auto px-3 py-2"
          onCreate={({ editor }) => handleEditorChange(editor)}
          isEmailMode={isEmailMode}
          onEmailModeChange={(mode) => {
            setIsEmailMode(mode);
            if (!mode && replyThread) {
              setReplyThread(undefined);
            }
          }}
          onTemplateClick={() => setShowTemplateDialog(true)}
        />

        <MessageToolbar
          onEmojiSelect={insertEmoji}
          onFileUpload={handleFileUpload}
          onSpecialCharacter={handleSpecialCharacter}
          onSignatureInsert={handleSignatureInsert}
          onSend={handleSend}
          isSendEnabled={isSendEnabled()}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          template={template ? { template: template.template } : undefined}
        />
      </div>

      <TemplateDialog
        open={showTemplateDialog}
        onOpenChange={setShowTemplateDialog}
        onTemplateSelect={handleTemplateSelect}
        templates={templates}
        isLoading={isRenderingTemplate}
        selectedTemplate={selectedTemplate}
      />
    </div>
  );
}
