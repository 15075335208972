import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useAxios from '@/hooks/use-axios';
import type {
  ThreadDB,
  TrackThreadRequest,
  ThreadTrackResponse,
  ThreadUntrackResponse,
  ThreadErrorResponse,
} from '@/types/thread';

/** React Query cache keys for threads */
const THREADS_QUERY_KEYS = {
  /** Base key for all thread queries */
  all: ['threads'] as const,
  /** Key builder for deal-specific thread queries */
  byDeal: (dealId: string) => [...THREADS_QUERY_KEYS.all, 'deal', dealId] as const,
} as const;

/**
 * Hook for managing email threads associated with a deal
 *
 * Provides functionality to:
 * - Fetch all threads for a specific deal
 * - Track new threads
 * - Untrack existing threads
 * - Track loading and error states
 * - Auto-refresh threads after mutations
 *
 * @param dealId - The ID of the deal to fetch/manage threads for
 * @returns Object containing:
 *  - threads: Array of threads associated with the deal
 *  - isLoading: Boolean indicating if threads are being fetched
 *  - error: Any error that occurred during fetching
 *  - trackThread: Function to track a new thread
 *  - untrackThread: Function to untrack an existing thread
 *  - isTracking: Boolean indicating if a thread is being tracked
 *  - isUntracking: Boolean indicating if a thread is being untracked
 */
export function useThreads(dealId: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();

  // Fetch threads for a deal
  const {
    data: threads,
    isLoading,
    error,
  } = useQuery({
    queryKey: THREADS_QUERY_KEYS.byDeal(dealId),
    queryFn: async () => {
      const { data } = await axios.get<ThreadDB[]>(`/threads/${dealId}`);
      return data;
    },
    refetchInterval: 10_000, // Refetch every 10 seconds
  });

  // Track a new thread
  const { mutateAsync: trackThread, isPending: isTracking } = useMutation({
    mutationFn: async ({ threadId, grantId }: Omit<TrackThreadRequest, 'dealId'>) => {
      const { data } = await axios.post<ThreadTrackResponse>(`/threads/track/${dealId}`, {
        dealId,
        threadId,
        grantId,
      });
      return data;
    },
    onMutate: async ({ threadId, grantId }) => {
      await queryClient.cancelQueries({ queryKey: THREADS_QUERY_KEYS.byDeal(dealId) });
      const previousThreads = queryClient.getQueryData<ThreadDB[]>(THREADS_QUERY_KEYS.byDeal(dealId));

      if (previousThreads) {
        // Add thread to cache with minimal required fields
        queryClient.setQueryData<ThreadDB[]>(
          THREADS_QUERY_KEYS.byDeal(dealId),
          [...previousThreads, {
            thread_id: threadId,
            grant_id: grantId,
          } as ThreadDB],
        );
      }

      return { previousThreads };
    },
    onError: (error: unknown, variables, context) => {
      if (context?.previousThreads) {
        queryClient.setQueryData(THREADS_QUERY_KEYS.byDeal(dealId), context.previousThreads);
      }

      // Handle specific API error responses
      if (error && typeof error === 'object' && 'response' in error) {
        const apiError = (error as { response: { data: ThreadErrorResponse } }).response.data;
        if (apiError.error.code === 'THREAD_ALREADY_TRACKED') {
          throw new Error(`Thread "${variables.threadId}" is already tracked for this deal`);
        }
      }
      throw error;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: THREADS_QUERY_KEYS.byDeal(dealId),
      });
    },
  });

  // Untrack an existing thread
  const { mutateAsync: untrackThread, isPending: isUntracking } = useMutation({
    mutationFn: async (threadId: string) => {
      const { data } = await axios.delete<ThreadUntrackResponse>(`/threads/untrack/${dealId}/${threadId}`);
      return data;
    },
    onMutate: async (threadId) => {
      await queryClient.cancelQueries({ queryKey: THREADS_QUERY_KEYS.byDeal(dealId) });
      const previousThreads = queryClient.getQueryData<ThreadDB[]>(THREADS_QUERY_KEYS.byDeal(dealId));

      if (previousThreads) {
        queryClient.setQueryData<ThreadDB[]>(
          THREADS_QUERY_KEYS.byDeal(dealId),
          previousThreads.filter((thread) => thread.thread_id !== threadId),
        );
      }

      return { previousThreads };
    },
    onError: (error: unknown, variables, context) => {
      if (context?.previousThreads) {
        queryClient.setQueryData(THREADS_QUERY_KEYS.byDeal(dealId), context.previousThreads);
      }

      // Handle specific API error responses
      if (error && typeof error === 'object' && 'response' in error) {
        const apiError = (error as { response: { data: ThreadErrorResponse } }).response.data;
        if (apiError.error.code === 'THREAD_NOT_FOUND') {
          throw new Error(`Thread "${variables}" not found or not tracked for this deal`);
        }
      }
      throw error;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: THREADS_QUERY_KEYS.byDeal(dealId),
      });
    },
  });

  return {
    threads,
    isLoading,
    error,
    trackThread,
    untrackThread,
    isTracking,
    isUntracking,
  };
}
