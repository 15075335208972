import { useState } from 'react';
import type { ReactNode } from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import type { VerificationItem, OFACCheck, FdicCheck, SignableNote } from '../types';

interface AddItemDialogProps {
  onAddItem: (item: VerificationItem) => void;
  trigger?: ReactNode;
}

export function AddItemDialog({ onAddItem, trigger }: AddItemDialogProps) {
  const [open, setOpen] = useState(false);
  const [itemType, setItemType] = useState('');
  const [itemTitle, setItemTitle] = useState('');

  const handleAddItem = () => {
    if (itemType === 'fdic') {
      const newItem: FdicCheck = {
        id: `fdic-${Date.now()}`,
        type: 'fdic',
        entityName: '',
        certNumber: '',
        searchResults: null,
        verified: false,
        signatures: [],
      };
      onAddItem(newItem);
    } else if (itemType === 'ofac') {
      const newItem: OFACCheck = {
        id: `ofac-${Date.now()}`,
        type: 'ofac',
        entityName: '',
        searchResults: null,
        verified: false,
        signatures: [],
      };
      onAddItem(newItem);
    } else {
      const newItem: SignableNote = {
        id: `note-${Date.now()}`,
        type: 'note',
        title: itemTitle || 'New Note',
        content: '',
        signatures: [],
      };
      onAddItem(newItem);
    }
    setItemType('');
    setItemTitle('');
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger || (
          <Button variant="outline" className="gap-2">
            <Plus className="size-4" />
            Add Item
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Verification Item</DialogTitle>
          <DialogDescription>
            Choose the type of verification item to add.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="type">Type</Label>
            <Select
              value={itemType}
              onValueChange={setItemType}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="ofac">OFAC Check</SelectItem>
                <SelectItem value="fdic">FDIC Check</SelectItem>
                <SelectItem value="note">Signable Note</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {itemType === 'note' && (
            <div className="grid gap-2">
              <Label htmlFor="title">Title</Label>
              <Input
                id="title"
                value={itemTitle}
                onChange={(e) => setItemTitle(e.target.value)}
                placeholder="Enter note title"
              />
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            onClick={handleAddItem}
            disabled={!itemType || (itemType === 'note' && !itemTitle)}
          >
            Add Item
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
