import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GridApi } from '@ag-grid-community/core';
import { RotateCcw } from 'lucide-react';
import AGDataGrid from '@/components/ag-data-grid';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import { useReports, type ReportMetadata } from '@/hooks/use-reports';
import { reportColumnDefs } from './components/report-grid-columns';

function ReportsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const gridApiRef = useRef<GridApi<ReportMetadata> | null>(null);
  const { registerGridApi } = useGrid();
  const navigate = useNavigate();
  const { setLabel, removeLabel } = useBreadcrumb();

  const { data: reports, isLoading } = useReports();

  useEffect(() => {
    const path = '/app/reports';
    setLabel(path, {
      name: 'Reports',
      path,
    });
    return () => removeLabel(path);
  }, [setLabel, removeLabel]);

  const handleRowDoubleClick = (event: { data: ReportMetadata | undefined }) => {
    if (!event.data)
      return;
    navigate(`/app/reports${event.data.path}`);
  };

  const handleGridReady = (params: { api: GridApi<ReportMetadata> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3">
        <h1 className="mt-2 text-xl font-bold">Reports</h1>
        <p className="text-sm text-muted-foreground">
          View and generate reports. Double-click a report to configure and run it.
        </p>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search reports..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={isLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={reportColumnDefs}
          rowData={reports || []}
          quickFilterText={searchQuery}
          onRowDoubleClicked={handleRowDoubleClick}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No reports found</span>'
          }
        />
      </div>
    </Container>
  );
}

export default ReportsPage;
