// src/pages/app/deals/index.tsx
import { useRef, useState, useMemo, useEffect } from 'react';
import type { GridApi } from '@ag-grid-community/core';
import { Archive, LayoutGrid, RotateCcw, Table2, ChevronDown, Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useGrid } from '@/contexts/grid-context';
import { useDeals, useUpdateDeal, type DealStage, type DealOutcome, type Deal } from '@/hooks/use-deals';
import { usePersistedState } from '@/hooks/use-persisted-state';
import { DealColumns } from './components/deal-columns';
import { dealColumnDefs } from './components/deal-grid-columns';

const DEAL_STAGES: DealStage[] = [
  'Annual Verification',
  'Pending Verification',
  'Organization Verification in Progress',
  'Name Selection in Progress',
  'Employment Verification in Progress',
  'WHOIS Update in Progress',
  'Failed Verification',
  'Verification Completed',
  'Verified Data Sent',
  'Completed',
  'Migrated',
];

const DEFAULT_SELECTED_STAGES = DEAL_STAGES.filter((stage) => stage !== 'Migrated');

const DEAL_OUTCOMES: DealOutcome[] = [
  'Approved',
  'Combined',
  'Consolidation',
  'Did not Register',
  'Domain Deleted',
  'Duplicate',
  'fTLD Domain',
  'Incomplete',
  'Ineligible',
  'Junk',
  'Name Selection',
  'No Renew',
  'No Reply',
  'No Verification Needed',
  'Not Wanted',
  'Organization',
  'Price',
  'Test',
  'Migrated',
];

const DEFAULT_SELECTED_OUTCOMES = DEAL_OUTCOMES.filter((outcome) => outcome !== 'Migrated');

type ViewMode = 'grid' | 'columns';

function DealsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [viewMode, setViewMode] = usePersistedState<ViewMode>('deals-view-mode', 'grid');
  const [selectedStages, setSelectedStages] = usePersistedState<DealStage[]>('deals-selected-stages', DEFAULT_SELECTED_STAGES);
  const [selectedOutcomes, setSelectedOutcomes] = usePersistedState<DealOutcome[]>('deals-selected-outcomes', DEFAULT_SELECTED_OUTCOMES);
  const [isFiltersOpen, setIsFiltersOpen] = usePersistedState('deals-filters-open', true);
  const gridApiRef = useRef<GridApi | null>(null);
  const { registerGridApi } = useGrid();

  const { data: deals, isLoading, error, isFetching } = useDeals();
  const updateDeal = useUpdateDeal();

  const handleSelectionChanged = (event: { api: { getSelectedRows: () => any[] } }) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleArchive = async () => {
    try {
      await Promise.all(
        selectedRows.map(async (row) =>
          updateDeal.mutateAsync({
            id: row.id,
            archived_at: new Date().toISOString(),
          }),
        ),
      );
      toast.success('Selected deals archived successfully');
      setSelectedRows([]);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleGridReady = (params: { api: GridApi }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
      setSelectedStages(DEFAULT_SELECTED_STAGES);
      setSelectedOutcomes(DEFAULT_SELECTED_OUTCOMES);
    }
  };

  const filteredDeals = useMemo(() => {
    if (!deals)
      return [];

    return deals.filter((deal) => {
      // Stage and outcome filters
      const stageMatch = selectedStages.includes(deal.deal_stage);
      const outcomeMatch = !deal.outcome || selectedOutcomes.includes(deal.outcome);

      // Search query filter
      if (!searchQuery)
        return stageMatch && outcomeMatch;

      const query = searchQuery.toLowerCase();
      const searchMatch = deal.domains.some((domain) => domain.toLowerCase().includes(query))
        || deal.deal_stage.toLowerCase().includes(query);

      return stageMatch && outcomeMatch && searchMatch;
    });
  }, [deals, searchQuery, selectedStages, selectedOutcomes]);

  if (error) {
    toast.error('Failed to load deals');
  }

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-xl font-bold">All Deals</h1>
          {isFetching && !isLoading && (
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Loader2 className="size-4 animate-spin" />
              <span>Fetching latest deals...</span>
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          <ToggleGroup
            type="single"
            value={viewMode}
            onValueChange={(value) => value && setViewMode(value as ViewMode)}
            aria-label="View mode"
          >
            <ToggleGroupItem
              value="grid"
              aria-label="Grid view"
              className="px-3"
            >
              <Table2 className="size-4" />
            </ToggleGroupItem>
            <ToggleGroupItem
              value="columns"
              aria-label="Column view"
              className="px-3"
            >
              <LayoutGrid className="size-4" />
            </ToggleGroupItem>
          </ToggleGroup>
          {selectedRows.length > 0 && (
            <Button
              variant="outline"
              onClick={handleArchive}
              disabled={updateDeal.isPending}
              className="gap-2"
            >
              <Archive className="size-4" />
              Archive Selected
            </Button>
          )}
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search deals..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>

      {viewMode === 'grid' && (
        <Collapsible
          open={isFiltersOpen}
          onOpenChange={setIsFiltersOpen}
          className="mb-6 space-y-2"
        >
          <div className="flex items-center gap-2">
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm" className="gap-2">
                <ChevronDown className={`size-4 transition-transform ${isFiltersOpen ? 'rotate-180' : ''}`} />
                Filters
              </Button>
            </CollapsibleTrigger>
            <div className="text-sm text-muted-foreground">
              {selectedStages.length}
              {' '}
              stages and
              {' '}
              {selectedOutcomes.length}
              {' '}
              outcomes selected
            </div>
          </div>

          <CollapsibleContent className="space-y-4">
            <div className="flex flex-wrap items-start gap-8">
              <div className="space-y-4">
                <Label className="text-sm font-medium">Deal Stages</Label>
                <div className="grid grid-cols-2 gap-x-8 gap-y-2">
                  {DEAL_STAGES.map((stage) => (
                    <div key={stage} className="flex items-center gap-2">
                      <Checkbox
                        id={`stage-${stage}`}
                        checked={selectedStages.includes(stage)}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setSelectedStages([...selectedStages, stage]);
                          } else {
                            setSelectedStages(selectedStages.filter((s) => s !== stage));
                          }
                        }}
                      />
                      <Label htmlFor={`stage-${stage}`} className="text-sm">{stage}</Label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="space-y-4">
                <Label className="text-sm font-medium">Deal Outcomes</Label>
                <div className="grid grid-cols-2 gap-x-8 gap-y-2">
                  {DEAL_OUTCOMES.map((outcome) => (
                    <div key={outcome} className="flex items-center gap-2">
                      <Checkbox
                        id={`outcome-${outcome}`}
                        checked={selectedOutcomes.includes(outcome)}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setSelectedOutcomes([...selectedOutcomes, outcome]);
                          } else {
                            setSelectedOutcomes(selectedOutcomes.filter((o) => o !== outcome));
                          }
                        }}
                      />
                      <Label htmlFor={`outcome-${outcome}`} className="text-sm">{outcome}</Label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      )}

      {viewMode === 'grid'
        ? (
            <div className={isLoading ? 'opacity-50' : ''}>
              <AGDataGrid
                columnDefs={dealColumnDefs}
                rowData={filteredDeals}
                quickFilterText={searchQuery}
                rowSelection={{ mode: 'multiRow' }}
                onGridReady={handleGridReady}
                onSelectionChanged={handleSelectionChanged}
                overlayLoadingTemplate={
                  isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
                }
                overlayNoRowsTemplate={
                  isLoading ? '' : '<span class="ag-overlay-no-rows-center">No deals found</span>'
                }
              />
            </div>
          )
        : (
            <DealColumns deals={filteredDeals} />
          )}
    </Container>
  );
}

export default DealsPage;
