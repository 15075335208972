import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { AtSign, Paperclip, Send, Smile } from 'lucide-react';
import { AiOutlineSignature } from 'react-icons/ai';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import type { MessageToolbarProps } from './types';

/**
 * Renders the message toolbar with various message composition actions
 * Features:
 * - Emoji picker with preview
 * - Special character insertion (@ symbol)
 * - Signature insertion
 * - File attachment handling
 * - Send button with enabled/disabled state
 */
export function MessageToolbar({
  onEmojiSelect,
  onFileUpload,
  onSpecialCharacter,
  onSignatureInsert,
  onSend,
  isSendEnabled,
  showEmojiPicker,
  setShowEmojiPicker,
  template,
}: MessageToolbarProps) {
  // Trigger the hidden file input when the attachment button is clicked
  const handleFileButtonClick = () => {
    const fileInput = document.querySelector('#file-upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div className="flex items-center justify-between border-t bg-muted/40 px-2 py-1">
      <div className="flex items-center gap-0.5">
        <Popover open={showEmojiPicker} onOpenChange={setShowEmojiPicker}>
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
            >
              <Smile className="size-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-auto border-none p-0"
            sideOffset={5}
            align="start"
          >
            <Picker
              data={data}
              onEmojiSelect={onEmojiSelect}
              theme="light"
              previewPosition="none"
            />
          </PopoverContent>
        </Popover>

        <Button
          variant="ghost"
          size="sm"
          className="size-8 p-0"
          onClick={() => onSpecialCharacter('@')}
        >
          <AtSign className="size-4" />
        </Button>

        <Button
          variant="ghost"
          size="sm"
          className="size-8 p-0"
          onClick={() => template && onSignatureInsert(template.template)}
        >
          <AiOutlineSignature className="size-4" />
        </Button>

        <div className="mx-1 h-4 w-px bg-border" />

        <div className="relative">
          <Button
            variant="ghost"
            size="sm"
            className="size-8 p-0"
            onClick={handleFileButtonClick}
          >
            <Paperclip className="size-4" />
          </Button>
          <input
            id="file-upload"
            type="file"
            multiple
            className="hidden"
            onChange={onFileUpload}
          />
        </div>
      </div>

      <Button
        size="sm"
        onClick={onSend}
        disabled={!isSendEnabled}
      >
        <Send className="mr-2 size-4" />
        Send
      </Button>
    </div>
  );
}
