import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { CalendarIcon, Download } from 'lucide-react';
import { toast } from 'sonner';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { executeReport } from '@/hooks/use-reports';
import { cn } from '@/lib/utils';

interface DealsSummaryFilters {
  startDate: Date;
  endDate: Date;
  status: string;
}

function DealsSummaryReport() {
  const { setLabel, removeLabel } = useBreadcrumb();
  const [isGenerating, setIsGenerating] = useState(false);
  const form = useForm<DealsSummaryFilters>({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
      status: 'all',
    },
  });

  useEffect(() => {
    const path = '/app/reports/deals-summary';
    setLabel(path, {
      name: 'Deals Summary Report',
      path,
    });
    return () => removeLabel(path);
  }, [setLabel, removeLabel]);

  const handleGenerateReport = async (data: DealsSummaryFilters) => {
    try {
      setIsGenerating(true);
      const base64Data = await executeReport({
        reportId: 'deals-summary',
        filters: {
          startDate: data.startDate,
          endDate: data.endDate,
          status: data.status,
        },
      });

      // Convert base64 to blob and download
      const blob = new Blob([atob(base64Data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = globalThis.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `deals-summary-${format(data.startDate, 'yyyy-MM-dd')}-to-${format(data.endDate, 'yyyy-MM-dd')}.xlsx`;
      document.body.append(a);
      a.click();
      globalThis.URL.revokeObjectURL(url);
      a.remove();

      toast.success('Report generated successfully');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Failed to generate report');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-6">
        <h1 className="text-xl font-bold">Deals Summary Report</h1>
        <p className="text-sm text-muted-foreground">
          Generate a summary report of all deals within a specified date range and status.
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleGenerateReport)} className="space-y-8">
          <div className="grid gap-6 md:grid-cols-2">
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Start Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            'w-full pl-3 text-left font-normal',
                            !field.value && 'text-muted-foreground',
                          )}
                        >
                          {field.value
                            ? (
                                format(field.value, 'PPP')
                              )
                            : (
                                <span>Pick a date</span>
                              )}
                          <CalendarIcon className="ml-auto size-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) =>
                          date > new Date() || date > form.getValues('endDate')}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    The start date for the report period
                  </FormDescription>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="endDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>End Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            'w-full pl-3 text-left font-normal',
                            !field.value && 'text-muted-foreground',
                          )}
                        >
                          {field.value
                            ? (
                                format(field.value, 'PPP')
                              )
                            : (
                                <span>Pick a date</span>
                              )}
                          <CalendarIcon className="ml-auto size-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) =>
                          date > new Date() || date < form.getValues('startDate')}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    The end date for the report period
                  </FormDescription>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Deal Status</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a status" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="all">All Statuses</SelectItem>
                      <SelectItem value="open">Open</SelectItem>
                      <SelectItem value="closed">Closed</SelectItem>
                      <SelectItem value="pending">Pending</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Filter deals by their current status
                  </FormDescription>
                </FormItem>
              )}
            />
          </div>

          <Button type="submit" disabled={isGenerating} className="gap-2">
            <Download className="size-4" />
            {isGenerating ? 'Generating...' : 'Generate Report'}
          </Button>
        </form>
      </Form>
    </Container>
  );
}

export default DealsSummaryReport;
