import * as React from 'react';
import { User, ChevronDown, ChevronRight, Globe, Copy, Users, Mail } from 'lucide-react';
import { toast } from 'sonner';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useDeal } from '@/contexts/deal-context';
import { useDeal as useCurrentDeal } from '@/hooks/use-deals';
import { usePartners } from '@/hooks/use-partners';

interface PersonCardProps {
  person: {
    name: string;
    legal_name: string;
    alt_name: string | null;
    email: string;
    alt_email: string | null;
    phone_number: string;
    alt_phone: string | null;
    fax_number: string | null;
    address_line_1: string;
    address_line_2: string | null;
    address_line_3: string | null;
    city: string;
    state_province: string;
    postal_code: string;
    country: string;
    title: string;
    organization_name: string;
    c_suite: boolean;
    sole_prop: boolean;
    new_legal_name: boolean;
    contact_ids: string[];
    associated_domains: string[];
    organization_licenses?: License[];
  };
  type: 'registrant' | 'verification';
}

interface License {
  authority: string;
  value: string;
  contact_ids: string[];
  license_ids: string[];
}

function PersonCard({ person, type }: PersonCardProps) {
  const [showDomains, setShowDomains] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);
  const [showLicenses, setShowLicenses] = React.useState(false);

  const handleCopy = async (text: string, label: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`${label} copied to clipboard`);
    } catch {
      toast.error('Failed to copy to clipboard');
    }
  };

  return (
    <div className="space-y-1 text-sm">
      <button
        type="button"
        onClick={() => setShowDetails(!showDetails)}
        className="flex w-full items-center justify-between rounded-md px-1 py-0.5 hover:bg-accent"
      >
        <div>
          <div className="font-medium">{person.name}</div>
          <div className="text-muted-foreground">{person.title}</div>
        </div>
        {showDetails ? <ChevronDown className="size-3" /> : <ChevronRight className="size-3" />}
      </button>

      {showDetails && (
        <div className="ml-2 space-y-1">
          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">RDDS Name:</div>
            <div className="flex items-center gap-1">
              <span>{person.legal_name}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.legal_name, 'RDDS Name')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          {person.alt_name && (
            <div className="flex items-center justify-between gap-2">
              <div className="text-muted-foreground">Alt Name:</div>
              <div className="flex items-center gap-1">
                <span>{person.alt_name}</span>
                <Button
                  size="sm"
                  variant="ghost"
                  className="size-4 p-0"
                  onClick={async () => handleCopy(person.alt_name!, 'Alt name')}
                >
                  <Copy className="size-3" />
                </Button>
              </div>
            </div>
          )}

          {person.organization_name && (
            <div className="flex items-center justify-between gap-2">
              <div className="text-muted-foreground">Organization:</div>
              <div className="flex items-center gap-1">
                <span>{person.organization_name}</span>
                <Button
                  size="sm"
                  variant="ghost"
                  className="size-4 p-0"
                  onClick={async () => handleCopy(person.organization_name, 'Organization')}
                >
                  <Copy className="size-3" />
                </Button>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">Email:</div>
            <div className="flex items-center gap-1">
              <span>{person.email}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.email, 'Email')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          {person.alt_email && (
            <div className="flex items-center justify-between gap-2">
              <div className="text-muted-foreground">Alt Email:</div>
              <div className="flex items-center gap-1">
                <span>{person.alt_email}</span>
                <Button
                  size="sm"
                  variant="ghost"
                  className="size-4 p-0"
                  onClick={async () => handleCopy(person.alt_email!, 'Alt email')}
                >
                  <Copy className="size-3" />
                </Button>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">Company Phone:</div>
            <div className="flex items-center gap-1">
              <span>{person.phone_number}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.phone_number, 'Company Phone')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          {person.alt_phone && (
            <div className="flex items-center justify-between gap-2">
              <div className="text-muted-foreground">Direct Phone:</div>
              <div className="flex items-center gap-1">
                <span>{person.alt_phone}</span>
                <Button
                  size="sm"
                  variant="ghost"
                  className="size-4 p-0"
                  onClick={async () => handleCopy(person.alt_phone!, 'Direct Phone')}
                >
                  <Copy className="size-3" />
                </Button>
              </div>
            </div>
          )}

          {person.fax_number && (
            <div className="flex items-center justify-between gap-2">
              <div className="text-muted-foreground">Fax:</div>
              <div className="flex items-center gap-1">
                <span>{person.fax_number}</span>
                <Button
                  size="sm"
                  variant="ghost"
                  className="size-4 p-0"
                  onClick={async () => handleCopy(person.fax_number!, 'Fax')}
                >
                  <Copy className="size-3" />
                </Button>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">Address:</div>
            <div className="flex items-center gap-1">
              <span>{person.address_line_1}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.address_line_1, 'Address')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          {person.address_line_2 && (
            <div className="flex items-center justify-between gap-2">
              <div className="text-muted-foreground">Address 2:</div>
              <div className="flex items-center gap-1">
                <span>{person.address_line_2}</span>
                <Button
                  size="sm"
                  variant="ghost"
                  className="size-4 p-0"
                  onClick={async () => handleCopy(person.address_line_2!, 'Address 2')}
                >
                  <Copy className="size-3" />
                </Button>
              </div>
            </div>
          )}

          {person.address_line_3 && (
            <div className="flex items-center justify-between gap-2">
              <div className="text-muted-foreground">Address 3:</div>
              <div className="flex items-center gap-1">
                <span>{person.address_line_3}</span>
                <Button
                  size="sm"
                  variant="ghost"
                  className="size-4 p-0"
                  onClick={async () => handleCopy(person.address_line_3!, 'Address 3')}
                >
                  <Copy className="size-3" />
                </Button>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">City:</div>
            <div className="flex items-center gap-1">
              <span>{person.city}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.city, 'City')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">State/Province:</div>
            <div className="flex items-center gap-1">
              <span>{person.state_province}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.state_province, 'State/Province')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">Postal Code:</div>
            <div className="flex items-center gap-1">
              <span>{person.postal_code}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.postal_code, 'Postal code')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="text-muted-foreground">Country:</div>
            <div className="flex items-center gap-1">
              <span>{person.country}</span>
              <Button
                size="sm"
                variant="ghost"
                className="size-4 p-0"
                onClick={async () => handleCopy(person.country, 'Country')}
              >
                <Copy className="size-3" />
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-2 text-xs">
            <div className="flex items-center gap-1">
              <div className="text-muted-foreground">C-Suite:</div>
              <div>{person.c_suite ? 'Yes' : 'No'}</div>
            </div>
            <div className="flex items-center gap-1">
              <div className="text-muted-foreground">Sole Prop:</div>
              <div>{person.sole_prop ? 'Yes' : 'No'}</div>
            </div>
            <div className="flex items-center gap-1">
              <div className="text-muted-foreground">New Legal Name:</div>
              <div>{person.new_legal_name ? 'Yes' : 'No'}</div>
            </div>
          </div>

          {/* Organization Licenses Section */}
          {person.organization_licenses && person.organization_licenses.length > 0 && (
            <div className="space-y-1">
              <button
                type="button"
                onClick={() => setShowLicenses(!showLicenses)}
                className="flex w-full items-center gap-2 text-xs text-muted-foreground hover:text-foreground"
              >
                {showLicenses ? <ChevronDown className="size-3" /> : <ChevronRight className="size-3" />}
                <span>
                  {person.organization_licenses.length}
                  {' '}
                  License
                  {person.organization_licenses.length === 1 ? '' : 's'}
                </span>
              </button>
              {showLicenses && (
                <div className="ml-5 space-y-1">
                  {person.organization_licenses.map((license) => (
                    <div key={`${license.authority}-${license.value}`} className="space-y-1">
                      <div className="flex items-center justify-between gap-2">
                        <div className="text-muted-foreground">Authority:</div>
                        <div className="flex items-center gap-1">
                          <span>{license.authority}</span>
                          <Button
                            size="sm"
                            variant="ghost"
                            className="size-4 p-0"
                            onClick={async () => handleCopy(license.authority, 'License authority')}
                          >
                            <Copy className="size-3" />
                          </Button>
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-2">
                        <div className="text-muted-foreground">Value:</div>
                        <div className="flex items-center gap-1">
                          <span>{license.value}</span>
                          <Button
                            size="sm"
                            variant="ghost"
                            className="size-4 p-0"
                            onClick={async () => handleCopy(license.value, 'License value')}
                          >
                            <Copy className="size-3" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* Associated Domains Section */}
      {person.associated_domains.length > 0 && (
        <div className="space-y-1">
          <button
            type="button"
            onClick={() => setShowDomains(!showDomains)}
            className="flex w-full items-center gap-2 text-xs text-muted-foreground hover:text-foreground"
          >
            {showDomains ? <ChevronDown className="size-3" /> : <ChevronRight className="size-3" />}
            <Globe className="size-3" />
            <span>
              {person.associated_domains.length}
              {' '}
              Domain
              {person.associated_domains.length === 1 ? '' : 's'}
            </span>
          </button>
          {showDomains && (
            <div className="ml-5 space-y-1">
              {person.associated_domains.map((domain) => (
                <div key={domain} className="flex items-center gap-1 text-xs">
                  <span>{domain}</span>
                  <Button
                    size="sm"
                    variant="ghost"
                    className="size-4 p-0"
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(domain);
                        toast.success('Domain copied to clipboard');
                      } catch {
                        toast.error('Failed to copy to clipboard');
                      }
                    }}
                  >
                    <Copy className="size-3" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function CompactContactsPanel() {
  const { dealId } = useDeal();
  const { data: deal, isLoading: isLoadingDeal } = useCurrentDeal(dealId!);
  const { data: partners, isLoading: isLoadingPartners } = usePartners();
  const [partnerSearch, setPartnerSearch] = React.useState('');

  const filteredPartners = React.useMemo(() => {
    if (!partners)
      return [];
    if (!partnerSearch)
      return partners;

    const query = partnerSearch.toLowerCase();
    return partners.filter((partner) =>
      partner.name.toLowerCase().includes(query)
      || partner.primary_contact.toLowerCase().includes(query)
      || partner.email.toLowerCase().includes(query),
    );
  }, [partners, partnerSearch]);

  if (isLoadingDeal || !deal) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-sm text-muted-foreground">Loading contacts...</div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-full">
      <div className="space-y-2 p-2">
        <Accordion
          type="multiple"
          className="w-full"
          defaultValue={['registrant-people', 'registrant-orgs', 'verification-people']}
        >
          {/* Registrant People */}
          {deal.registrants.people.length > 0 && (
            <AccordionItem value="registrant-people" className="border-b-0">
              <AccordionTrigger className="py-2 hover:no-underline">
                <div className="flex items-center gap-2 text-sm">
                  <User className="size-3" />
                  <span>Registrant Contacts</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="space-y-3 pb-3">
                {deal.registrants.people.map((person) => (
                  <PersonCard
                    key={`${person.email}-${person.legal_name}`}
                    person={person}
                    type="registrant"
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}

          {/* Verification People */}
          {deal.verification_contacts.people.length > 0 && (
            <AccordionItem value="verification-people" className="border-b-0">
              <AccordionTrigger className="py-2 hover:no-underline">
                <div className="flex items-center gap-2 text-sm">
                  <User className="size-3" />
                  <span>Verification Contacts</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="space-y-3 pb-3">
                {deal.verification_contacts.people.map((person) => (
                  <PersonCard
                    key={`${person.email}-${person.legal_name}`}
                    person={person}
                    type="verification"
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}

          {/* Partners */}
          {partners && partners.length > 0 && (
            <AccordionItem value="partners" className="border-b-0">
              <AccordionTrigger className="py-2 hover:no-underline">
                <div className="flex items-center gap-2 text-sm">
                  <Users className="size-3" />
                  <span>Partners</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="space-y-3 pb-3">
                <div className="mb-2">
                  <Input
                    type="search"
                    placeholder="Search partners..."
                    value={partnerSearch}
                    onChange={(e) => setPartnerSearch(e.target.value)}
                    className="h-8"
                  />
                </div>
                <div className="space-y-2">
                  {filteredPartners.map((partner) => (
                    <div key={partner.id} className="space-y-0.5 rounded-md px-1 py-0.5 text-xs hover:bg-accent">
                      <div className="font-medium">{partner.name}</div>
                      <div className="flex items-center gap-1 text-muted-foreground">
                        <span>{partner.primary_contact}</span>
                        <Button
                          size="sm"
                          variant="ghost"
                          className="size-4 p-0"
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(partner.primary_contact);
                              toast.success('Contact copied to clipboard');
                            } catch {
                              toast.error('Failed to copy to clipboard');
                            }
                          }}
                        >
                          <Copy className="size-3" />
                        </Button>
                      </div>
                      <div className="flex items-center gap-1 text-muted-foreground">
                        <Mail className="size-3" />
                        <span className="truncate">{partner.email}</span>
                        <Button
                          size="sm"
                          variant="ghost"
                          className="size-4 p-0"
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(partner.email);
                              toast.success('Email copied to clipboard');
                            } catch {
                              toast.error('Failed to copy to clipboard');
                            }
                          }}
                        >
                          <Copy className="size-3" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          )}
        </Accordion>
      </div>
    </ScrollArea>
  );
}
