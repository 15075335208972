import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Form, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Switch } from '@/components/ui/switch';
import type { BatchResult } from '@/hooks/use-annual-verification-batches';
import type { SchedulerTimeValue } from '@/types/scheduler';
import { getCurrentEST } from '@/types/scheduler';
import { SchedulerTimeSelect } from './scheduler-time-select';

interface SchedulerSheetProps {
  batch: BatchResult | null;
  isOpen: boolean;
  onClose: () => void;
  onSchedule: (time: SchedulerTimeValue, note?: string) => void;
}

interface FormValues {
  scheduledTime: SchedulerTimeValue;
  isForced: boolean;
  forcedNote: string;
}

export function SchedulerSheet({ batch, isOpen, onClose, onSchedule }: SchedulerSheetProps) {
  const form = useForm<FormValues>({
    defaultValues: {
      scheduledTime: getCurrentEST(),
      isForced: false,
      forcedNote: '',
    },
  });

  if (!batch)
    return null;

  const handleSubmit = (values: FormValues) => {
    onSchedule(values.scheduledTime, values.isForced ? values.forcedNote : undefined);
    onClose();
  };

  const isForced = form.watch('isForced');

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Schedule Batch</SheetTitle>
        </SheetHeader>

        <div className="mt-6 max-h-[calc(100vh-8rem)] overflow-y-auto pr-6">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
              <div className="space-y-2">
                <h3 className="text-sm font-medium">Batch Details</h3>
                <div className="rounded-md border p-4 text-sm">
                  <div className="space-y-1">
                    <p>
                      <span className="font-medium">Criteria Type:</span>
                      {' '}
                      {batch.criteria_type === 'registrant_email' && 'Registrant Email'}
                      {batch.criteria_type === 'license' && 'License'}
                      {batch.criteria_type === 'registrant_org_name' && 'Organization Name'}
                    </p>
                    <p>
                      <span className="font-medium">Criteria Value:</span>
                      {' '}
                      {batch.criteria}
                    </p>
                    <p>
                      <span className="font-medium">Domains:</span>
                      {' '}
                      {batch.domains.length}
                    </p>
                  </div>
                </div>
              </div>

              <FormField
                control={form.control}
                name="scheduledTime"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Schedule Time (EST)</FormLabel>
                    <SchedulerTimeSelect field={field} />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isForced"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">Forced Verification</FormLabel>
                      <div className="text-sm text-muted-foreground">
                        Convert this batch to forced verification
                      </div>
                    </div>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormItem>
                )}
              />

              {isForced && (
                <FormField
                  control={form.control}
                  name="forcedNote"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Forced Verification Note</FormLabel>
                      <Input
                        {...field}
                        placeholder="Enter reason for forced verification"
                        required
                      />
                    </FormItem>
                  )}
                />
              )}

              <div className="flex justify-end gap-3">
                <Button variant="outline" type="button" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Schedule</Button>
              </div>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  );
}
