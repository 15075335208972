import { useQuery } from '@tanstack/react-query';
import { useAxios } from '@/hooks/use-axios';
import type {
  NylasFolder,
  ListFolderQueryParams,
  GetFolderQueryParams,
  GetFoldersResponse,
} from '@/types/folder';

/**
 * Query keys for React Query cache management.
 * Structured hierarchically for consistent cache invalidation.
 */
export const NYLAS_FOLDERS_QUERY_KEYS = {
  all: ['nylasFolders'] as const,
  list: (grantId?: string) => ['nylasFolders', 'list', grantId] as const,
  batch: (grantId?: string, folderIds?: string[]) =>
    ['nylasFolders', 'batch', grantId, folderIds] as const,
} as const;

/**
 * Response type for the Nylas folders list API endpoint
 */
type NylasFoldersResponse = {
  data: NylasFolder[];
  requestId: string;
};

/**
 * Response type for the Nylas folders batch API endpoint
 */
type NylasFoldersBatchResponse = {
  success: boolean;
  data: GetFoldersResponse;
  message: string;
};

/**
 * Configuration options for the useNylasFolders hook
 */
type UseFoldersOptions = {
  /** Whether the query should automatically run. Defaults to true */
  enabled?: boolean;
  /** Optional query parameters for filtering and pagination */
  queryParams?: Partial<ListFolderQueryParams>;
};

/**
 * Configuration options for the useNylasFoldersBatch hook
 */
type UseFoldersBatchOptions = {
  /** Whether the query should automatically run. Defaults to true */
  enabled?: boolean;
  /** Optional query parameters for selecting specific fields */
  queryParams?: Partial<GetFolderQueryParams>;
};

/**
 * Hook to fetch folders for a given Nylas grant ID
 *
 * @param grantId - The Nylas grant ID to fetch folders for
 * @param options - Configuration options for the query
 *
 * @returns A query result object containing:
 * - data: Array of Nylas folders if the query was successful
 * - isLoading: Boolean indicating if the query is in progress
 * - error: Error object if the query failed
 * - Other standard react-query result properties
 *
 * @example
 * ```tsx
 * const { data: folders, isLoading } = useNylasFolders(grantId, {
 *   queryParams: { limit: 100 }
 * })
 * ```
 */
export function useNylasFolders(grantId: string | undefined, options: UseFoldersOptions = {}) {
  const axios = useAxios();
  const { enabled = true, queryParams } = options;

  return useQuery({
    queryKey: NYLAS_FOLDERS_QUERY_KEYS.list(grantId),
    queryFn: async () => {
      if (!grantId) {
        throw new Error('Grant ID is required');
      }

      const { data } = await axios.get<NylasFoldersResponse>(`/nylas/grants/${grantId}/folders`, {
        params: queryParams,
      });
      console.log('folders:', data.data);
      return data.data;
    },
    enabled: !!grantId && enabled,
  });
}

/**
 * Hook to fetch multiple folders by their IDs for a given Nylas grant ID
 *
 * @param grantId - The Nylas grant ID to fetch folders for
 * @param folderIds - Array of folder IDs to fetch
 * @param options - Configuration options for the query
 *
 * @returns A query result object containing:
 * - data: Array of Nylas folder responses if the query was successful
 * - isLoading: Boolean indicating if the query is in progress
 * - error: Error object if the query failed
 * - Other standard react-query result properties
 *
 * @example
 * ```tsx
 * const { data: folders, isLoading } = useNylasFoldersBatch(grantId, ['folder1', 'folder2'], {
 *   queryParams: { select: 'id,name' }
 * })
 * ```
 */
export function useNylasFoldersBatch(
  grantId: string | undefined,
  folderIds: string[] | undefined,
  options: UseFoldersBatchOptions = {},
) {
  const axios = useAxios();
  const { enabled = true, queryParams } = options;

  return useQuery({
    queryKey: NYLAS_FOLDERS_QUERY_KEYS.batch(grantId, folderIds),
    queryFn: async () => {
      if (!grantId) {
        throw new Error('Grant ID is required');
      }
      if (!folderIds?.length) {
        throw new Error('At least one folder ID is required');
      }

      const { data } = await axios.post<NylasFoldersBatchResponse>(
        `/nylas/grants/${grantId}/folders/batch`,
        { folderIds },
        { params: queryParams },
      );

      // Return just the folder data
      const folders = data.data.folders.map((folder) => folder.data).filter(Boolean);

      return folders;
    },
    enabled: !!grantId && !!folderIds?.length && enabled,
  });
}
