import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import type { TemplateDialogProps } from './types';

const DEFAULT_TEMPLATES: TemplateDialogProps['templates'] = [];

/**
 * Renders a dialog for searching and selecting email templates
 * Features:
 * - Search functionality for filtering templates
 * - Displays template names and descriptions
 * - Shows loading state when template is being rendered
 */
export function TemplateDialog({
  open,
  onOpenChange,
  onTemplateSelect,
  templates = DEFAULT_TEMPLATES,
  isLoading,
  selectedTemplate,
}: TemplateDialogProps) {
  const [searchQuery, setSearchQuery] = useState('');

  // Filter and sort templates based on search query
  const sortedAndFilteredTemplates = templates
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .filter((template) =>
      template.name.toLowerCase().includes(searchQuery.toLowerCase())
      || template.short_description?.toLowerCase().includes(searchQuery.toLowerCase()),
    );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle className="text-base">Insert Template</DialogTitle>
        </DialogHeader>
        <div className="mb-2">
          <Input
            placeholder="Search templates..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full"
          />
        </div>
        <ScrollArea className="max-h-[250px]">
          <div className="space-y-0.5">
            {sortedAndFilteredTemplates?.map((template) => (
              <div
                key={template.name}
                className={`cursor-pointer rounded-md px-3 py-2 hover:bg-ftld-primary-50 ${isLoading && selectedTemplate === template.name ? 'opacity-50' : ''
                  }`}
                onClick={() => onTemplateSelect(template)}
              >
                <h3 className="text-sm font-medium">{template.name}</h3>
                <p className="text-xs text-muted-foreground">
                  {template.short_description}
                </p>
                {isLoading && selectedTemplate === template.name && (
                  <p className="mt-0.5 text-xs text-muted-foreground">Loading...</p>
                )}
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
