import { useRef, useState, useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import type { GridApi, RowDoubleClickedEvent, SortDirection, ColDef } from '@ag-grid-community/core';
import { format, compareAsc, isPast } from 'date-fns';
import { RotateCcw, Loader2 } from 'lucide-react';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useGrid } from '@/contexts/grid-context';
import { useDeal } from '@/hooks/use-deals';
import { usePersistedState } from '@/hooks/use-persisted-state';
import { useTasks, useAssignedTasks } from '@/hooks/use-tasks';
import type { Task } from '@/hooks/use-tasks';
import { cn } from '@/lib/utils';

type ViewMode = 'all' | 'assigned';

const DealCell = memo(({ dealId }: { dealId: string }) => {
  const { data: deal } = useDeal(dealId);
  if (!deal)
    return null;

  return (
    <Link
      to={`/app/deals/${dealId}`}
      className="hover:text-primary"
    >
      <div className="flex flex-col gap-0.5">
        <div>{deal.domains.join(', ')}</div>
        <div className="text-xs text-muted-foreground">
          {format(new Date(deal.created_at), 'MMM d')}
        </div>
      </div>
    </Link>
  );
});

function createTaskColumnDefs(defaultSort = true): ColDef<Task>[] {
  return [
    {
      field: 'deal_id',
      headerName: 'Deal',
      flex: 2,
      minWidth: 200,
      cellRenderer: (params: { value: string }) => (
        <DealCell dealId={params.value} />
      ),
      sortable: true,
      filter: true,
    },
    {
      field: 'task',
      headerName: 'Task',
      flex: 2,
      minWidth: 200,
      filter: true,
    },
    {
      field: 'due',
      headerName: 'Due Date',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params: { value: string }) => {
        if (!params.value)
          return '';
        return new Date(params.value).toLocaleString();
      },
      sortable: true,
      sort: defaultSort ? ('asc' as SortDirection) : undefined,
      filter: true,
    },
    {
      field: 'done',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      valueFormatter: (params: { value: string | null; data?: Task }) => {
        if (!params.data)
          return '';
        if (params.value)
          return 'Completed';
        return isPast(new Date(params.data.due)) ? 'Overdue' : 'Pending';
      },
      cellClass: (params: { value: string | null; data?: Task }) => {
        if (!params.data)
          return '';
        if (params.value)
          return 'text-success';
        return isPast(new Date(params.data.due)) ? 'text-destructive' : 'text-warning';
      },
      filter: true,
    },
    {
      field: 'assigned_to_users',
      headerName: 'Assigned To',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params: { value: Array<{ firstName?: string; lastName?: string }> }) => {
        if (!params.value)
          return '';
        return params.value
          .map((user) => `${user.firstName || ''} ${user.lastName || ''}`.trim())
          .join(', ');
      },
      filter: true,
    },
  ];
}

function TasksPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = usePersistedState<ViewMode>('tasks-view-mode', 'assigned');
  const [showPending, setShowPending] = usePersistedState('tasks-show-pending', true);
  const [showOverdue, setShowOverdue] = usePersistedState('tasks-show-overdue', true);
  const [showCompleted, setShowCompleted] = usePersistedState('tasks-show-completed', false);
  const gridApiRef = useRef<GridApi | null>(null);
  const { registerGridApi } = useGrid();
  const { data: allTasks, isLoading: isLoadingAll, isFetching: isFetchingAll } = useTasks();
  const { data: assignedTasks, isLoading: isLoadingAssigned, isFetching: isFetchingAssigned } = useAssignedTasks();

  const filteredTasks = useMemo(() => {
    const baseTasks = viewMode === 'all' ? allTasks : assignedTasks;
    if (!baseTasks)
      return [];

    return baseTasks.filter((task) => {
      if (task.done) {
        return showCompleted;
      }
      const isOverdue = isPast(new Date(task.due));
      return isOverdue ? showOverdue : showPending;
    });
  }, [allTasks, assignedTasks, viewMode, showPending, showOverdue, showCompleted]);

  const isLoading = viewMode === 'all' ? isLoadingAll : isLoadingAssigned;
  const isFetching = viewMode === 'all' ? isFetchingAll : isFetchingAssigned;
  const columnDefs = useMemo(() => createTaskColumnDefs(true), []);

  const handleGridReady = (params: { api: GridApi }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-xl font-bold">Tasks</h1>
          {isFetching && !isLoading && (
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Loader2 className="size-4 animate-spin" />
              <span>Fetching latest tasks...</span>
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-6">
            <Tabs value={viewMode} onValueChange={(value) => setViewMode(value as ViewMode)}>
              <TabsList>
                <TabsTrigger value="assigned">Your Tasks</TabsTrigger>
                <TabsTrigger value="all">All Tasks</TabsTrigger>
              </TabsList>
            </Tabs>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Checkbox
                  id="show-pending"
                  checked={showPending}
                  onCheckedChange={(checked) => setShowPending(checked as boolean)}
                />
                <Label htmlFor="show-pending">Pending</Label>
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  id="show-overdue"
                  checked={showOverdue}
                  onCheckedChange={(checked) => setShowOverdue(checked as boolean)}
                />
                <Label htmlFor="show-overdue">Overdue</Label>
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  id="show-completed"
                  checked={showCompleted}
                  onCheckedChange={(checked) => setShowCompleted(checked as boolean)}
                />
                <Label htmlFor="show-completed">Completed</Label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search tasks..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={isLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={columnDefs}
          rowData={filteredTasks}
          quickFilterText={searchQuery}
          onGridReady={handleGridReady}
          paginationPageSize={50}
          getRowClass={(params) => {
            if (params.data && !params.data.done && isPast(new Date(params.data.due))) {
              return 'bg-destructive/5 hover:bg-destructive/10';
            }
            return '';
          }}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No tasks found</span>'
          }
        />
      </div>
    </Container>
  );
}

export default TasksPage;
