// src/pages/app/deals/components/deal-columns.tsx
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Calendar, ArrowRight } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import type { Deal, DealStage } from '@/hooks/use-deals';

const STAGES: DealStage[] = [
  'Annual Verification',
  'Pending Verification',
  'Organization Verification in Progress',
  'Name Selection in Progress',
  'Employment Verification in Progress',
  'WHOIS Update in Progress',
  'Failed Verification',
  'Verification Completed',
  'Verified Data Sent',
  'Completed',
];

function DealCard({ deal }: { deal: Deal }) {
  return (
    <Link
      to={`/app/deals/${deal.id}`}
      className="block transition-colors hover:bg-muted/50"
    >
      <div className="border-b border-border p-3 last:border-0">
        <div className="space-y-1 text-sm">
          <div className="font-medium text-foreground">
            {deal.domains.join(', ')}
          </div>
          <div className="flex items-center gap-2 text-xs text-muted-foreground">
            <Calendar className="size-3" />
            <span>{format(new Date(deal.created_at), 'MMM d')}</span>
            <ArrowRight className="size-3" />
            <span>{format(new Date(deal.updated_at), 'MMM d')}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export function DealColumns({ deals }: { deals: Deal[] }) {
  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:grid-cols-4">
      {STAGES.map((stage) => {
        const stageDeals = deals.filter((deal) => deal.deal_stage === stage);
        const count = stageDeals.length;

        return (
          <Card key={stage} className="overflow-hidden">
            <CardHeader className="border-b border-border p-3">
              <CardTitle className="flex items-center justify-between text-sm font-medium">
                <span>{stage}</span>
                <span className="rounded-full bg-muted px-2 py-0.5 text-xs">
                  {count}
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent className="max-h-[570px] overflow-y-auto p-0">
              {count > 0
                ? stageDeals.map((deal) => (
                    <DealCard key={deal.id} deal={deal} />
                  ))
                : (
                    <div className="p-3 text-center text-sm text-muted-foreground">
                      No deals
                    </div>
                  )}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}
