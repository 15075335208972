import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Calendar, Globe2, RotateCcw, Building2, FileText } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useDeal } from '@/hooks/use-deals';
import { useDomains } from '@/hooks/use-domains';

export function DomainsPanel() {
  const { dealId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const { data: domains, isLoading } = useDomains();
  const { data: deal } = useDeal(dealId!);
  const [filterByOrg, setFilterByOrg] = useState(false);
  const [filterByLicense, setFilterByLicense] = useState(false);

  const organizationNames = useMemo(() => {
    if (!deal?.registrants?.people)
      return [];
    const names = new Set<string>();

    // Add organization names from registrant people
    for (const person of deal.registrants.people) {
      if (person.organization_name) {
        names.add(person.organization_name);
      }
    }

    // Add organization names from verification contacts
    for (const person of deal.verification_contacts?.people || []) {
      if (person.organization_name) {
        names.add(person.organization_name);
      }
    }

    return [...names];
  }, [deal]);

  const regulatoryLicenses = useMemo(() => {
    if (!deal?.registrants?.people)
      return [];
    const licenses = new Set<string>();

    // Add licenses from registrant people
    for (const person of deal.registrants.people) {
      if (person.organization_licenses) {
        for (const license of person.organization_licenses) {
          licenses.add(`${license.authority},${license.value}`);
        }
      }
    }

    return [...licenses];
  }, [deal]);

  const filteredDomains = useMemo(() => {
    if (!domains)
      return [];

    let filtered = domains;

    // Apply organization filter if enabled
    if (filterByOrg && organizationNames.length > 0) {
      filtered = filtered.filter((domain) =>
        domain.organization_legal_name && organizationNames.some((org) =>
          domain.organization_legal_name.toLowerCase().includes(org.toLowerCase()),
        ),
      );
    }

    // Apply license filter if enabled
    if (filterByLicense && regulatoryLicenses.length > 0) {
      filtered = filtered.filter((domain) =>
        domain.licenses && regulatoryLicenses.some((license) => {
          const domainLicenses = domain.licenses.split(',').map((l) => l.trim());
          const searchLicense = license.trim().replace(',', ' ');

          // Check for exact matches first (for standalone license types)
          if (domainLicenses.some((dl) => dl.toLowerCase() === searchLicense.toLowerCase())) {
            return true;
          }

          // If the search license contains a space, it might be an authority + number combination
          if (searchLicense.includes(' ')) {
            const [authority, number] = searchLicense.split(' ');
            return domainLicenses.some((dl) => {
              const parts = dl.split(' ');
              return parts.length === 2
                && parts[0].toLowerCase() === authority.toLowerCase()
                && parts[1] === number;
            });
          }

          return false;
        }),
      );
    }

    // Apply search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter((domain) =>
        (domain.name && domain.name.toLowerCase().includes(query))
        || (domain.organization_legal_name && domain.organization_legal_name.toLowerCase().includes(query))
        || (domain.licenses && domain.licenses.toLowerCase().includes(query)),
      );
    }

    return filtered;
  }, [domains, searchQuery, filterByOrg, filterByLicense, organizationNames, regulatoryLicenses]);

  return (
    <div className="flex h-full max-h-[600px] flex-col overflow-hidden">
      <div className="flex flex-col gap-3 border-b p-4">
        <div className="flex items-center gap-3">
          <div className="flex-1">
            <Input
              type="search"
              placeholder="Search domains..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <Button
            variant="outline"
            onClick={() => {
              setSearchQuery('');
              setFilterByOrg(false);
              setFilterByLicense(false);
            }}
            className="gap-2"
          >
            <RotateCcw className="size-4" />
            Clear
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant={filterByOrg ? 'secondary' : 'outline'}
            size="sm"
            onClick={() => setFilterByOrg(!filterByOrg)}
            className="gap-2"
            disabled={organizationNames.length === 0}
            title={organizationNames.length === 0 ? 'No organizations found in deal' : undefined}
          >
            <Building2 className="size-4" />
            Filter by Organizations
            {organizationNames.length > 0 && (
              <Badge variant="secondary" className="ml-2">
                {organizationNames.length}
              </Badge>
            )}
          </Button>
          <Button
            variant={filterByLicense ? 'secondary' : 'outline'}
            size="sm"
            onClick={() => setFilterByLicense(!filterByLicense)}
            className="gap-2"
            disabled={regulatoryLicenses.length === 0}
            title={regulatoryLicenses.length === 0 ? 'No licenses found in deal' : undefined}
          >
            <FileText className="size-4" />
            Filter by Licenses
            {regulatoryLicenses.length > 0 && (
              <Badge variant="secondary" className="ml-2">
                {regulatoryLicenses.length}
              </Badge>
            )}
          </Button>
        </div>
      </div>
      <ScrollArea className="h-[calc(600px-56px)]">
        <div className="p-4">
          {isLoading
            ? (
                <div className="py-6 text-center text-sm text-muted-foreground">
                  Loading domains...
                </div>
              )
            : (filteredDomains.length === 0
                ? (
                    <div className="py-6 text-center text-sm text-muted-foreground">
                      No domains found
                    </div>
                  )
                : (
                    filteredDomains.map((domain) => (
                      <div
                        key={domain.name}
                        className="mb-4 rounded-lg border bg-card p-4 shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground"
                      >
                        <div className="min-w-0 space-y-3">
                          <div className="flex items-start justify-between gap-4">
                            <div className="min-w-0 flex-1">
                              <div className="flex items-center gap-2">
                                <Globe2 className="size-4 text-muted-foreground" />
                                <span className="text-sm font-medium">
                                  {domain.name}
                                </span>
                              </div>
                              <div className="mt-1 text-sm text-muted-foreground">
                                {domain.organization_legal_name}
                              </div>
                            </div>
                            <div className="flex items-center gap-1.5 text-xs text-muted-foreground">
                              <Calendar className="size-3" />
                              <span>
                                {(domain.last_verified
                                  ? format(new Date(domain.last_verified), 'MMM d, yyyy')
                                  : 'Never verified')}
                              </span>
                            </div>
                          </div>
                          {domain.licenses && (
                            <div className="flex flex-wrap gap-2">
                              {domain.licenses.split(',').map((license, index) => (
                                <Badge key={index} variant="secondary">
                                  {license.trim()}
                                </Badge>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ))}
        </div>
      </ScrollArea>
    </div>
  );
}
