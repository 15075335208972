import EmailRecipientInput from '@/components/email-recipient-input';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Toggle } from '@/components/ui/toggle';
import type { EmailFieldsProps } from './types';

/**
 * Renders the email-specific input fields (To, CC, Subject)
 * Uses EmailRecipientInput for handling email addresses with chip-style UI
 */
export function EmailFields({
  control,
  emailSubject,
  setEmailSubject,
  showCc,
  setShowCc,
  showBcc,
  setShowBcc,
  emailInputRef,
  ccInputRef,
  bccInputRef,
}: EmailFieldsProps) {
  return (
    <div className="mb-2 space-y-2">
      <div className="flex items-center gap-2">
        <Label htmlFor="to" className="w-16">To:</Label>
        <div className="flex-1">
          <EmailRecipientInput
            ref={emailInputRef}
            control={control}
            name="recipients"
          />
        </div>
        <Toggle
          type="button"
          variant="outline"
          size="sm"
          pressed={showCc}
          onClick={() => setShowCc(!showCc)}
          className="text-muted-foreground hover:text-foreground"
        >
          CC
        </Toggle>
        <Toggle
          type="button"
          variant="outline"
          size="sm"
          pressed={showBcc}
          onClick={() => setShowBcc(!showBcc)}
          className="text-muted-foreground hover:text-foreground"
        >
          BCC
        </Toggle>
      </div>
      {showCc && (
        <div className="flex items-center gap-2">
          <Label htmlFor="cc" className="w-16">CC:</Label>
          <div className="flex-1">
            <EmailRecipientInput
              ref={ccInputRef}
              control={control}
              name="ccRecipients"
              placeholder="Enter CC'd recipients"
            />
          </div>
        </div>
      )}
      {showBcc && (
        <div className="flex items-center gap-2">
          <Label htmlFor="bcc" className="w-16">BCC:</Label>
          <div className="flex-1">
            <EmailRecipientInput
              ref={bccInputRef}
              control={control}
              name="bccRecipients"
              placeholder="Enter BCC'd recipients"
            />
          </div>
        </div>
      )}
      <div className="flex items-center gap-2">
        <Label htmlFor="subject" className="w-16">Subject:</Label>
        <Input
          id="subject"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
          placeholder="Enter subject"
          className="flex-1"
        />
      </div>
    </div>
  );
}
