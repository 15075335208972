import type { ColDef } from '@ag-grid-community/core';
import type { EmailTemplate } from '@/hooks/use-email-templates';

export const templateColumnDefs: ColDef<EmailTemplate>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    sortable: true,
    filter: true,
    sort: 'asc',
    sortIndex: 0,
  },
  {
    field: 'to',
    headerName: 'To',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'short_description',
    headerName: 'Short Description',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'tld',
    headerName: 'TLD',
    flex: 0.5,
    sortable: true,
    filter: true,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    flex: 1,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString();
    },
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    flex: 1,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString();
    },
  },
];
