import { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { AlertCircle, Mail, MailX } from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useDeal } from '@/contexts/deal-context';
import { useBouncesByEmail } from '@/hooks/use-bounces';
import { useDeal as useDealData } from '@/hooks/use-deals';

export function BouncesPanel() {
  const { dealId } = useDeal();
  const { data: deal } = useDealData(dealId!);
  const { getBouncesByEmailAsync, data: bounces, isLoading } = useBouncesByEmail();

  // Extract all unique emails from registrant and verification contacts
  const emails = useMemo(() => {
    if (!deal)
      return [];

    const emailSet = new Set<string>();

    // Add registrant and verification contact emails
    for (const person of deal.registrants.people) {
      if (person.email)
        emailSet.add(person.email);
      if (person.alt_email)
        emailSet.add(person.alt_email);
    }

    for (const person of deal.verification_contacts.people) {
      if (person.email)
        emailSet.add(person.email);
      if (person.alt_email)
        emailSet.add(person.alt_email);
    }

    return [...emailSet];
  }, [deal]);

  // Fetch bounces when emails are available
  useEffect(() => {
    if (emails.length > 0) {
      void getBouncesByEmailAsync({ emails });
    }
  }, [emails, getBouncesByEmailAsync]);

  if (!deal) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-sm text-muted-foreground">Loading deal...</div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-sm text-muted-foreground">Loading bounce records...</div>
      </div>
    );
  }

  if (!bounces || bounces.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-2 text-muted-foreground">
        <Mail className="size-8" />
        <div className="text-sm">No bounce records found</div>
        <div className="text-xs">
          {emails.length}
          {' '}
          email
          {emails.length === 1 ? '' : 's'}
          {' '}
          checked
        </div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-full">
      <div className="space-y-4 p-4">
        <div className="flex items-center gap-2 text-sm text-muted-foreground">
          <AlertCircle className="size-4" />
          <span>
            Found
            {' '}
            {bounces.length}
            {' '}
            bounce
            {bounces.length === 1 ? '' : 's'}
            {' '}
            for
            {' '}
            {emails.length}
            {' '}
            email
            {emails.length === 1 ? '' : 's'}
          </span>
        </div>

        <div className="space-y-4">
          {bounces.map((bounce) => (
            <div
              key={bounce.id}
              className="rounded-lg border bg-card p-4 shadow-sm"
            >
              <div className="mb-2 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <MailX className="size-4 text-destructive" />
                  <span className="font-medium">{bounce.email}</span>
                </div>
                <div className="text-sm text-muted-foreground">
                  {format(new Date(bounce.created_at), 'MMM d, yyyy h:mm a')}
                </div>
              </div>
              <div className="space-y-1 text-sm">
                <div>
                  <span className="text-muted-foreground">From: </span>
                  {bounce.from}
                </div>
                <div>
                  <span className="text-muted-foreground">Subject: </span>
                  {bounce.subject}
                </div>
                <div>
                  <span className="text-muted-foreground">Description: </span>
                  {bounce.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ScrollArea>
  );
}
