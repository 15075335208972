import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import * as z from 'zod';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { usePublicOnboarding } from '@/hooks/use-public-onboarding';
import { countries } from './data/countries';
import { registrars } from './data/registrars';
import { getStatesForCountry } from './data/states';

const BANK_REGULATORY_AUTHORITIES = [
  'Australian Prudential Regulation Authority',
  'BaFin',
  'Financial Conduct Authority',
  'Office of the Superintendent of Financial Institutions',
  'FDIC',
  'FRS',
  'OCC',
  'National Regulator',
  'Other',
];

const INSURANCE_REGULATORY_AUTHORITIES = [
  'Australian Prudential Regulatory Authority',
  'BaFin',
  'Financial Conduct Authority',
  'Office of the Superintendent of Financial Institutions',
  'U.S. - State Regulator',
  'National Regulator',
  'Other',
];

const BANK_REFERRAL_OPTIONS = [
  'Conversation with a Peer Banker',
  'Outreach from .Bank | fTLD',
  'Online Educational Information (LinkedIn, .Bank/fTLD website, etc.)',
  'Banking Journal/Periodical',
  'Podcast/Webinar',
  'State Association',
  'National Association',
  'Trade Show',
  'Outreach from Registrar',
  'Other',
];

const INSURANCE_REFERRAL_OPTIONS = [
  'Another insurance company',
  'fTLD outreach',
  'Webinar',
  'Association',
  'Trade show',
  'Ad/Social Media',
  'Registrar',
];

const formSchema = z.object({
  // Domain Information
  domainNames: z.string().min(1, { message: 'Domain name(s) are required' }),
  isNewBrand: z.boolean().default(false),

  // Registrant Information
  roleName: z.string().min(1, { message: 'Role Name is required' }),
  roleEmail: z.string().email({ message: 'Valid Role Email is required' }),
  registrantName: z.string().min(1, { message: 'Name is required' }),
  registrantTitle: z.string().min(1, { message: 'Title is required' }),
  registrantEmail: z.string().email({ message: 'Valid email is required' }),
  registrantPhone: z.string().min(1, { message: 'Company phone number is required' }),
  registrantPhoneCountry: z.string().min(1, { message: 'Country code is required' }),
  registrantDirectPhone: z.string().min(1, { message: 'Direct phone number is required' }),
  registrantDirectPhoneCountry: z.string().min(1, { message: 'Country code is required' }),
  // Organization Information
  orgLegalName: z.string().min(1, { message: 'Organization name is required' }),
  isNewLegalName: z.boolean().default(false),
  addressLine1: z.string().min(1, { message: 'Address is required' }),
  addressLine2: z.string().optional(),
  addressLine3: z.string().optional(),
  country: z.string().min(1, { message: 'Country is required' }),
  city: z.string().min(1, { message: 'City is required' }),
  state: z.string().min(1, { message: 'State is required' }),
  zipCode: z.string().min(1, { message: 'Zip code is required' }),
  faxNumber: z.string().optional(),
  faxCountry: z.string().optional(),

  // Verification Contact
  isSoleProprietor: z.boolean().default(false),
  verifierName: z.string().min(1, { message: 'Name is required' }),
  verifierTitle: z.string().min(1, { message: 'Title is required' }),
  verifierEmail: z.string().email({ message: 'Valid email is required' }),
  verifierPhoneCountry: z.string().min(1, { message: 'Country code is required' }),
  verifierPhone: z.string().min(1, { message: 'Phone number is required' }),

  // Regulatory Information
  applicationType: z.enum([
    'Retail Banks/Savings Association',
    'Insurance Company/Agent/Broker',
    'Association',
    'Government Regulator',
    'Other',
  ]),
  regulatoryAuthority: z.string().optional(),
  regulatoryAuthorityName: z.string().optional(),
  regulatoryLicenseNumber: z.string().optional(),

  // Supporting Information
  supportingFiles: z.any().optional(),
  additionalInfo: z.string().optional(),

  // Registrar Choice
  registrarChoice: z.string().optional(),

  // Application Referral
  referralSources: z.array(z.string()).min(1, { message: 'Please select at least one option' }),

  // Terms & Conditions
  privacyPolicy: z.boolean().refine((val) => val === true, { message: 'You must agree to the Privacy Policy' }),
  dataConsent: z.boolean().refine((val) => val === true, { message: 'You must consent to data collection' }),
  registrarConsent: z.boolean().refine((val) => val === true, { message: 'You must consent to data sharing' }),

  // Mailing List
  joinMailingList: z.boolean().default(false),
}).refine((data) => {
  // Skip validation if sole proprietor
  if (data.isSoleProprietor) {
    return true;
  }

  // Check if verification contact fields match registrant fields
  // Note: We exclude title from validation as it's allowed to be the same
  if (data.verifierName === data.registrantName) {
    return false;
  }

  if (data.verifierEmail === data.registrantEmail) {
    return false;
  }

  if ((data.verifierPhone === data.registrantPhone) && (data.verifierPhoneCountry === data.registrantPhoneCountry)) {
    return false;
  }

  return true;
}, {
  message: 'Verification contact name, email, and phone must be different from registrant details.',
  path: ['verifierName'], // This will show the error under the verifierName field
}).refine((data) => {
  if (!data.regulatoryAuthority || !['U.S. - State Regulator', 'National Regulator', 'Other'].includes(data.regulatoryAuthority)) {
    return true;
  }
  return data.regulatoryAuthorityName && data.regulatoryAuthorityName.length > 0;
}, {
  message: 'Please specify the regulatory authority name',
  path: ['regulatoryAuthorityName'],
});

export default function PublicForm() {
  const [isBankDomain, setIsBankDomain] = useState(false);
  const [regulatoryAuthorities, setRegulatoryAuthorities] = useState(INSURANCE_REGULATORY_AUTHORITIES);
  const [referralOptions, setReferralOptions] = useState(INSURANCE_REFERRAL_OPTIONS);
  const [preFillDomain, setPreFillDomain] = useState<string | null>(null);

  useEffect(() => {
    const checkDomainType = () => {
      try {
        // Read from our own URL parameters instead of parent
        const currentUrl = new URL(globalThis.location.href);
        const parentSite = currentUrl.searchParams.get('parent') || '';
        const domainParam = currentUrl.searchParams.get('domain');
        const isBankParent = parentSite === 'register.bank';

        setIsBankDomain(isBankParent);
        setRegulatoryAuthorities(isBankParent ? BANK_REGULATORY_AUTHORITIES : INSURANCE_REGULATORY_AUTHORITIES);
        setReferralOptions(isBankParent ? BANK_REFERRAL_OPTIONS : INSURANCE_REFERRAL_OPTIONS);

        if (domainParam) {
          setPreFillDomain(`${domainParam}${isBankParent ? '.bank' : '.insurance'}`);
        }
      } catch (error) {
        // If there's any error, default to insurance
        console.warn('Error processing URL parameters:', error);
        setIsBankDomain(false);
        setRegulatoryAuthorities(INSURANCE_REGULATORY_AUTHORITIES);
        setReferralOptions(INSURANCE_REFERRAL_OPTIONS);
      }
    };

    checkDomainType();
  }, []);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      isNewBrand: false,
      isNewLegalName: false,
      isSoleProprietor: false, // Only allow sole proprietor for insurance
      joinMailingList: false,
      referralSources: [],
      registrantPhoneCountry: '+1',
      registrantDirectPhoneCountry: '+1',
      faxCountry: '+1',
      verifierPhoneCountry: '+1',
      domainNames: preFillDomain || '',
    },
  });

  // Update domain names when preFillDomain changes
  useEffect(() => {
    if (preFillDomain) {
      form.setValue('domainNames', preFillDomain);
    }
  }, [preFillDomain, form]);

  // Add useEffect to handle sole proprietor changes
  useEffect(() => {
    const isSoleProprietor = form.watch('isSoleProprietor');
    if (isSoleProprietor) {
      form.setValue('verifierName', form.getValues('registrantName'));
      form.setValue('verifierTitle', form.getValues('registrantTitle'));
      form.setValue('verifierEmail', form.getValues('registrantEmail'));
      form.setValue('verifierPhoneCountry', form.getValues('registrantPhoneCountry'));
      form.setValue('verifierPhone', form.getValues('registrantPhone'));
    }
  }, [form.watch('isSoleProprietor')]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const { mutate: submitForm, isPending } = usePublicOnboarding();

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => {
    // Format phone numbers to include country code and prepare submission data
    const {
      registrantPhoneCountry,
      verifierPhoneCountry,
      faxCountry,
      regulatoryAuthorityName,
      ...submissionData
    } = {
      ...values,
      registrantPhone: `${values.registrantPhoneCountry}${values.registrantPhone}`.replaceAll(/\s+/g, ''),
      verifierPhone: `${values.verifierPhoneCountry}${values.verifierPhone}`.replaceAll(/\s+/g, ''),
      faxNumber: values.faxNumber
        ? `${values.faxCountry}${values.faxNumber}`.replaceAll(/\s+/g, '')
        : undefined,
    };

    // If regulatoryAuthorityName exists, use it instead of the dropdown value
    const regulatoryAuthority = regulatoryAuthorityName || submissionData.regulatoryAuthority;

    submitForm(
      {
        ...submissionData,
        regulatoryAuthority,
        onProgress: setUploadProgress,
      },
      {
        onSuccess: (response) => {
          toast.success(response.message || 'Form submitted successfully');
          form.reset();
          setUploadProgress(0);
        },
        onError: (error) => {
          toast.error(error.message);
          setUploadProgress(0);
        },
      },
    );
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 px-2">
        <p className="text-sm text-gray-500">Fields marked with * are required</p>

        {/* Domain Information */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Domain Information</h2>
          <p className="text-sm text-gray-500">
            Please use the search tool in step 1 above to confirm all domain name(s) entered here are available.
          </p>

          <FormField
            control={form.control}
            name="domainNames"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Domain Name(s)*</FormLabel>
                <FormControl>
                  <Input placeholder={`example${isBankDomain ? '.bank' : '.insurance'}`} {...field} />
                </FormControl>
                <FormDescription>
                  Enter your desired
                  {' '}
                  {isBankDomain ? '.Bank' : '.Insurance'}
                  {' '}
                  domain name(s), separated by commas (e.g.,
                  {' '}
                  {isBankDomain ? 'bankname.bank' : 'insurancename.insurance'}
                  )
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="isNewBrand"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Check if the requested domain name(s) relate to a new brand, product or service
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </div>

        {/* Registrant Information */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Registrant Information</h2>
          <p className="text-sm text-gray-500">
            Registrant Contact must be different from the Verification Contact information.
          </p>
          <p className="text-sm text-gray-500">
            For maximum privacy protection and to prevent a single point of failure, a role name (e.g., Domain Admin) and role email (e.g., domainadmin@bankname.bank) must be provided.
          </p>

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="roleName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role Name*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription>
                    Enter Role Name (e.g., Domain Admin; two words only) for Public WHOIS/RDDS.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="roleEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role Email*</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormDescription>
                    Enter Role Email (e.g., domainadmin@companyname.bank; must be operational) for Public WHOIS/RDDS.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="registrantName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name*</FormLabel>
                  <FormControl>
                    <Input placeholder="First and Last name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="registrantTitle"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title / Position*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="registrantEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email*</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormDescription>
                    Must be a company email address (not Gmail, Yahoo, etc.)
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-3 gap-2">
            <FormField
              control={form.control}
              name="registrantPhoneCountry"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Code*</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="+1" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {(() => {
                        const dialCodeMap: Record<string, string[]> = {};
                        for (const country of countries) {
                          if (!country.dialCode)
                            continue;
                          if (!dialCodeMap[country.dialCode]) {
                            dialCodeMap[country.dialCode] = [];
                          }
                          dialCodeMap[country.dialCode].push(country.label);
                        }
                        return Object.entries(dialCodeMap).map(([dialCode, labels]) => (
                          <SelectItem key={dialCode} value={dialCode}>
                            {dialCode}
                          </SelectItem>
                        ));
                      })()}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="registrantPhone"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Company Phone*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription>
                    For Public WHOIS/RDDS.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-3 gap-2">
            <FormField
              control={form.control}
              name="registrantDirectPhoneCountry"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Code*</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="+1" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {(() => {
                        const dialCodeMap: Record<string, string[]> = {};
                        for (const country of countries) {
                          if (!country.dialCode)
                            continue;
                          if (!dialCodeMap[country.dialCode]) {
                            dialCodeMap[country.dialCode] = [];
                          }
                          dialCodeMap[country.dialCode].push(country.label);
                        }
                        return Object.entries(dialCodeMap).map(([dialCode, labels]) => (
                          <SelectItem key={dialCode} value={dialCode}>
                            {dialCode}
                          </SelectItem>
                        ));
                      })()}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="registrantDirectPhone"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Direct Phone*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Organization Information */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Registrant Organization Information</h2>

          <div className="space-y-4">
            <div className="flex items-end gap-4">
              <FormField
                control={form.control}
                name="orgLegalName"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Organization Legal Name*</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isNewLegalName"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        Check if this is a new legal name/organization
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="addressLine1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address Line 1*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="addressLine2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address Line 2</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="addressLine3"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address Line 3</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country*</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select country" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {countries.map((country) => (
                          <SelectItem key={country.value} value={country.value}>
                            {country.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City*</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State / Province*</FormLabel>
                    {form.watch('country') === 'US'
                      ? (
                          <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select state" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {getStatesForCountry('US').map((state) => (
                                <SelectItem key={state.value} value={state.value}>
                                  {state.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )
                      : (
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                        )}
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="zipCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Zip / Postal Code*</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid grid-cols-3 gap-2">
              <FormField
                control={form.control}
                name="faxCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Fax Code</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="+1" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {(() => {
                          const dialCodeMap: Record<string, string[]> = {};
                          for (const country of countries) {
                            if (!country.dialCode)
                              continue;
                            if (!dialCodeMap[country.dialCode]) {
                              dialCodeMap[country.dialCode] = [];
                            }
                            dialCodeMap[country.dialCode].push(country.label);
                          }
                          return Object.entries(dialCodeMap).map(([dialCode, labels]) => (
                            <SelectItem key={dialCode} value={dialCode}>
                              {dialCode}
                            </SelectItem>
                          ));
                        })()}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="faxNumber"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Fax Number</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        {/* Verification Contact */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Verification Contact Information</h2>
          <p className="text-sm text-gray-500">
            The Verification Contact must be a C-Suite employee of the Registrant Organization.
          </p>

          {!isBankDomain && (
            <FormField
              control={form.control}
              name="isSoleProprietor"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>
                      Check this box if you are a sole proprietor
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />
          )}

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="verifierName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name*</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={form.watch('isSoleProprietor')} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="verifierTitle"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title / Position*</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={form.watch('isSoleProprietor')} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="verifierEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email*</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} disabled={form.watch('isSoleProprietor')} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-3 gap-2">
              <FormField
                control={form.control}
                name="verifierPhoneCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code*</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value} disabled={form.watch('isSoleProprietor')}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="+1" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {(() => {
                          const dialCodeMap: Record<string, string[]> = {};
                          for (const country of countries) {
                            if (!country.dialCode)
                              continue;
                            if (!dialCodeMap[country.dialCode]) {
                              dialCodeMap[country.dialCode] = [];
                            }
                            dialCodeMap[country.dialCode].push(country.label);
                          }
                          return Object.entries(dialCodeMap).map(([dialCode, labels]) => (
                            <SelectItem key={dialCode} value={dialCode}>
                              {dialCode}
                            </SelectItem>
                          ));
                        })()}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="verifierPhone"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Phone*</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={form.watch('isSoleProprietor')} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        {/* Regulatory Information */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Regulatory Information</h2>
          <p className="text-sm text-gray-500">
            Additional information may be required based on your Application Type.
          </p>

          <FormField
            control={form.control}
            name="applicationType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Application Type*</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select application type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem
                      value={isBankDomain ? 'Retail Banks/Savings Association' : 'Insurance Company/Agent/Broker'}
                    >
                      {isBankDomain ? 'Retail Banks/Savings Association' : 'Insurance Company/Agent/Broker'}
                    </SelectItem>
                    <SelectItem value="Association">Association</SelectItem>
                    <SelectItem value="Government Regulator">Government Regulator</SelectItem>
                    <SelectItem value="Other">Other</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          {form.watch('applicationType') === (isBankDomain ? 'Retail Banks/Savings Association' : 'Insurance Company/Agent/Broker') && (
            <>
              <FormField
                control={form.control}
                name="regulatoryAuthority"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Regulatory Authority*</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select regulatory authority" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {regulatoryAuthorities.map((authority) => (
                          <SelectItem key={authority} value={authority}>
                            {authority}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {form.watch('regulatoryAuthority') && ['U.S. - State Regulator', 'National Regulator', 'Other'].includes(form.watch('regulatoryAuthority') || '') && (
                <FormField
                  control={form.control}
                  name="regulatoryAuthorityName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Specify Authority Name*</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter the name of the regulatory authority" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="regulatoryLicenseNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {isBankDomain ? 'Regulatory Charter/License Number*' : 'Regulatory License Number*'}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
        </div>

        {/* Supporting Information */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Supporting Information</h2>
          <p className="text-sm text-gray-500">
            Please provide at least two (2) examples to support how the domain name(s) correspond to your
            organization's legal name or branding (e.g., trademark, trade name, service mark).
            A registered trademark is not required to register a domain name.
          </p>

          <FormField
            control={form.control}
            name="supportingFiles"
            render={({ field: { onChange } }) => ( // Only destructure onChange
              <FormItem>
                <FormControl>
                  <Input
                    type="file"
                    multiple
                    onChange={(e) => {
                      const files = [...e.target.files || []];
                      onChange(files);
                    }}
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp,.tiff,.tif,.ico,.webp,.svg,.xlsx,.csv,.xls,.ppt,.pptx,.zip,.txt,.rar,.7z,.md,.html"
                  />
                </FormControl>
                <FormDescription>
                  Upload up to three files (max 2MB total) such as advertising materials, brand usage examples,
                  or trademark registrations
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="additionalInfo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Additional Supporting Information</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Additional details such as URLs or trademark registration numbers"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Registrar Choice */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Registrar of Choice</h2>
          <p className="text-sm text-gray-500">
            Select your preferred registrar from the approved list below.
          </p>

          <FormField
            control={form.control}
            name="registrarChoice"
            render={({ field }) => (
              <FormItem>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select registrar" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {registrars.map((registrar) => (
                      <SelectItem key={registrar.value} value={registrar.value}>
                        {registrar.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Application Referral */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Application Referral</h2>
          <p className="text-sm text-gray-500">
            How did you hear about
            {' '}
            {isBankDomain ? '.Bank' : '.Insurance'}
            ?*
          </p>

          <div className={`space-y-2 ${isBankDomain ? 'grid grid-cols-2 gap-4' : ''}`}>
            {referralOptions.map((option) => (
              <FormField
                key={option}
                control={form.control}
                name="referralSources"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 py-1">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(option)}
                        onCheckedChange={(checked) => {
                          const currentValue = field.value || [];
                          if (checked) {
                            field.onChange([...currentValue, option]);
                          } else {
                            field.onChange(currentValue.filter((value) => value !== option));
                          }
                        }}
                      />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {option}
                    </FormLabel>
                  </FormItem>
                )}
              />
            ))}
          </div>
        </div>

        {/* Terms & Conditions */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Terms & Conditions</h2>
          <p className="text-sm text-gray-500">
            To proceed with your verification application, you will need to consent and agree to the following:
          </p>

          <FormField
            control={form.control}
            name="privacyPolicy"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    I agree to
                    {' '}
                    <a
                      href={isBankDomain ? 'https://register.bank/privacy-policy/' : 'https://register.insurance/privacy-policy/'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      fTLD's Privacy Policy
                    </a>
                    . Please review fTLD's Privacy Policy for more information
                    on how we use your data and how to withdraw your consent.
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="dataConsent"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    I consent to fTLD collecting and storing my data from this application form to create an
                    account for verification of my application information and to support management of domain
                    registrations and for any other fTLD services.
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="registrarConsent"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    I consent to fTLD sharing my data with an
                    {' '}
                    <a
                      href={isBankDomain ? 'https://register.bank/registrars/' : 'https://register.insurance/registrars/'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      fTLD-Approved Registrar
                    </a>
                    {' '}
                    of my choice and this
                    registrar sharing my data with their reseller (if applicable) to facilitate the domain
                    registration process.
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </div>

        {/* Mailing List */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Join Our Mailing List</h2>
          <p className="text-sm text-gray-500">
            fTLD would love to send you information about offers and services. We will treat your personal
            details with the utmost care. You may unsubscribe at any time.
          </p>

          <FormField
            control={form.control}
            name="joinMailingList"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Yes, I would like to hear about offers and services from fTLD.
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </div>

        <Button
          type="submit"
          className="w-full"
          style={{ backgroundColor: 'rgb(56, 89, 60)' }}
          disabled={isPending}
        >
          {isPending
            ? (uploadProgress > 0 ? `Submitting (${uploadProgress}%)` : 'Submitting...')
            : `Submit ${isBankDomain ? '.Bank' : '.Insurance'} Application`}
        </Button>
      </form>
    </Form>
  );
}
