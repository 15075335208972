import { useQuery, useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

export interface ReportMetadata {
  id: string;
  name: string;
  description: string;
  path: string;
}

// This would normally come from an API, but for now it's hardcoded
const AVAILABLE_REPORTS: ReportMetadata[] = [
  {
    id: 'contacts-by-domain-status',
    name: 'Contacts by Domain Status',
    description: 'List of contacts filtered by domain status, TLD, and contact type',
    path: '/contacts-by-domain-status',
  },
  // {
  //   id: 'deals-summary',
  //   name: 'Deals Summary Report',
  //   description: 'A summary of all deals including status, value, and key dates',
  //   path: '/deals-summary',
  // },
  // {
  //   id: 'domain-activity',
  //   name: 'Domain Activity Report',
  //   description: 'Detailed activity log for all domains including registration and renewal dates',
  //   path: '/domain-activity',
  // },
];

export function useReports() {
  return useQuery({
    queryKey: ['reports'],
    queryFn: async () => AVAILABLE_REPORTS,
  });
}

export interface ReportExecutionParams {
  reportId: string;
  filters: Record<string, unknown>;
}

const API_BASE_URL = '/reports';

/**
 * API Contract for Contacts by Domain Status Report
 *
 * Request:
 * POST /reports/contacts-by-domain-status
 * {
 *   domainStatus: string[];  // Array of statuses: 'live', 'parked', 'redirect_in_zone', 'redirect_out_of_zone', 'inactive'
 *   tld: string[];          // Array of TLDs: '.Bank', '.Insurance'
 *   contactType: string[];  // Array of contact types: 'registrant', 'verification'
 * }
 *
 * Response:
 * {
 *   data: {
 *     contacts: Array<{
 *       name: string;
 *       email: string;
 *       phone: string;
 *       organization: string;
 *     }>;
 *   }
 * }
 *
 * Error Response:
 * {
 *   error: {
 *     message: string;
 *     status: number;
 *   }
 * }
 */

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

interface Contact {
  name: string;
  email: string;
  phone: string;
  organization: string;
}

interface ReportResponse {
  data: {
    contacts: Contact[];
  };
}

interface ContactsByDomainStatusParams {
  domainStatus: string[];
  tld: string[];
  contactType: string[];
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested report type was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// Generate Contacts by Domain Status Report
export function useContactsByDomainStatusReport() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (params: ContactsByDomainStatusParams) => {
      try {
        const { data } = await axios.post<ReportResponse>(
          `${API_BASE_URL}/contacts-by-domain-status`,
          params,
        );
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// This is a temporary function to maintain backward compatibility
// while we migrate to the new hook-based approach
export async function executeReport({ reportId, filters }: { reportId: string; filters: Record<string, unknown> }): Promise<string> {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 1000));
  // Return mock base64 data
  return 'bW9jayBiYXNlNjQgZGF0YQ==';
}
