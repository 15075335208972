export const countries = [
  { value: 'AF', label: 'Afghanistan', dialCode: '+93' },
  { value: 'AX', label: 'Åland Islands', dialCode: '+358' },
  { value: 'AL', label: 'Albania', dialCode: '+355' },
  { value: 'DZ', label: 'Algeria', dialCode: '+213' },
  { value: 'AS', label: 'American Samoa', dialCode: '+1684' },
  { value: 'AD', label: 'Andorra', dialCode: '+376' },
  { value: 'AO', label: 'Angola', dialCode: '+244' },
  { value: 'AI', label: 'Anguilla', dialCode: '+1264' },
  { value: 'AQ', label: 'Antarctica', dialCode: '+672' },
  { value: 'AG', label: 'Antigua & Barbuda', dialCode: '+1268' },
  { value: 'AR', label: 'Argentina', dialCode: '+54' },
  { value: 'AM', label: 'Armenia', dialCode: '+374' },
  { value: 'AW', label: 'Aruba', dialCode: '+297' },
  { value: 'AU', label: 'Australia', dialCode: '+61' },
  { value: 'AT', label: 'Austria', dialCode: '+43' },
  { value: 'AZ', label: 'Azerbaijan', dialCode: '+994' },
  { value: 'BS', label: 'Bahamas', dialCode: '+1242' },
  { value: 'BH', label: 'Bahrain', dialCode: '+973' },
  { value: 'BD', label: 'Bangladesh', dialCode: '+880' },
  { value: 'BB', label: 'Barbados', dialCode: '+1246' },
  { value: 'BY', label: 'Belarus', dialCode: '+375' },
  { value: 'BE', label: 'Belgium', dialCode: '+32' },
  { value: 'BZ', label: 'Belize', dialCode: '+501' },
  { value: 'BJ', label: 'Benin', dialCode: '+229' },
  { value: 'BM', label: 'Bermuda', dialCode: '+1441' },
  { value: 'BT', label: 'Bhutan', dialCode: '+975' },
  { value: 'BO', label: 'Bolivia', dialCode: '+591' },
  { value: 'BQ', label: 'Caribbean Netherlands', dialCode: '+599' },
  { value: 'BA', label: 'Bosnia & Herzegovina', dialCode: '+387' },
  { value: 'BW', label: 'Botswana', dialCode: '+267' },
  { value: 'BV', label: 'Bouvet Island', dialCode: '+47' },
  { value: 'BR', label: 'Brazil', dialCode: '+55' },
  { value: 'IO', label: 'British Indian Ocean Territory', dialCode: '+246' },
  { value: 'BN', label: 'Brunei', dialCode: '+673' },
  { value: 'BG', label: 'Bulgaria', dialCode: '+359' },
  { value: 'BF', label: 'Burkina Faso', dialCode: '+226' },
  { value: 'BI', label: 'Burundi', dialCode: '+257' },
  { value: 'KH', label: 'Cambodia', dialCode: '+855' },
  { value: 'CM', label: 'Cameroon', dialCode: '+237' },
  { value: 'CA', label: 'Canada', dialCode: '+1' },
  { value: 'CV', label: 'Cape Verde', dialCode: '+238' },
  { value: 'KY', label: 'Cayman Islands', dialCode: '+1345' },
  { value: 'CF', label: 'Central African Republic', dialCode: '+236' },
  { value: 'TD', label: 'Chad', dialCode: '+235' },
  { value: 'CL', label: 'Chile', dialCode: '+56' },
  { value: 'CN', label: 'China', dialCode: '+86' },
  { value: 'CX', label: 'Christmas Island', dialCode: '+61' },
  { value: 'CC', label: 'Cocos (Keeling) Islands', dialCode: '+61' },
  { value: 'CO', label: 'Colombia', dialCode: '+57' },
  { value: 'KM', label: 'Comoros', dialCode: '+269' },
  { value: 'CG', label: 'Congo - Brazzaville', dialCode: '+242' },
  { value: 'CD', label: 'Congo - Kinshasa', dialCode: '+243' },
  { value: 'CK', label: 'Cook Islands', dialCode: '+682' },
  { value: 'CR', label: 'Costa Rica', dialCode: '+506' },
  { value: 'CI', label: 'Côte d\'Ivoire', dialCode: '+225' },
  { value: 'HR', label: 'Croatia', dialCode: '+385' },
  { value: 'CU', label: 'Cuba', dialCode: '+53' },
  { value: 'CW', label: 'Curaçao', dialCode: '+599' },
  { value: 'CY', label: 'Cyprus', dialCode: '+357' },
  { value: 'CZ', label: 'Czechia', dialCode: '+420' },
  { value: 'DK', label: 'Denmark', dialCode: '+45' },
  { value: 'DJ', label: 'Djibouti', dialCode: '+253' },
  { value: 'DM', label: 'Dominica', dialCode: '+1767' },
  { value: 'DO', label: 'Dominican Republic', dialCode: '+1' },
  { value: 'EC', label: 'Ecuador', dialCode: '+593' },
  { value: 'EG', label: 'Egypt', dialCode: '+20' },
  { value: 'SV', label: 'El Salvador', dialCode: '+503' },
  { value: 'GQ', label: 'Equatorial Guinea', dialCode: '+240' },
  { value: 'ER', label: 'Eritrea', dialCode: '+291' },
  { value: 'EE', label: 'Estonia', dialCode: '+372' },
  { value: 'ET', label: 'Ethiopia', dialCode: '+251' },
  { value: 'FK', label: 'Falkland Islands', dialCode: '+500' },
  { value: 'FO', label: 'Faroe Islands', dialCode: '+298' },
  { value: 'FJ', label: 'Fiji', dialCode: '+679' },
  { value: 'FI', label: 'Finland', dialCode: '+358' },
  { value: 'FR', label: 'France', dialCode: '+33' },
  { value: 'GF', label: 'French Guiana', dialCode: '+594' },
  { value: 'PF', label: 'French Polynesia', dialCode: '+689' },
  { value: 'TF', label: 'French Southern Territories', dialCode: '+262' },
  { value: 'GA', label: 'Gabon', dialCode: '+241' },
  { value: 'GM', label: 'Gambia', dialCode: '+220' },
  { value: 'GE', label: 'Georgia', dialCode: '+995' },
  { value: 'DE', label: 'Germany', dialCode: '+49' },
  { value: 'GH', label: 'Ghana', dialCode: '+233' },
  { value: 'GI', label: 'Gibraltar', dialCode: '+350' },
  { value: 'GR', label: 'Greece', dialCode: '+30' },
  { value: 'GL', label: 'Greenland', dialCode: '+299' },
  { value: 'GD', label: 'Grenada', dialCode: '+1473' },
  { value: 'GP', label: 'Guadeloupe', dialCode: '+590' },
  { value: 'GU', label: 'Guam', dialCode: '+1671' },
  { value: 'GT', label: 'Guatemala', dialCode: '+502' },
  { value: 'GG', label: 'Guernsey', dialCode: '+44' },
  { value: 'GN', label: 'Guinea', dialCode: '+224' },
  { value: 'GW', label: 'Guinea-Bissau', dialCode: '+245' },
  { value: 'GY', label: 'Guyana', dialCode: '+592' },
  { value: 'HT', label: 'Haiti', dialCode: '+509' },
  { value: 'HM', label: 'Heard & McDonald Islands', dialCode: '+672' },
  { value: 'VA', label: 'Vatican City', dialCode: '+379' },
  { value: 'HN', label: 'Honduras', dialCode: '+504' },
  { value: 'HK', label: 'Hong Kong', dialCode: '+852' },
  { value: 'HU', label: 'Hungary', dialCode: '+36' },
  { value: 'IS', label: 'Iceland', dialCode: '+354' },
  { value: 'IN', label: 'India', dialCode: '+91' },
  { value: 'ID', label: 'Indonesia', dialCode: '+62' },
  { value: 'IR', label: 'Iran', dialCode: '+98' },
  { value: 'IQ', label: 'Iraq', dialCode: '+964' },
  { value: 'IE', label: 'Ireland', dialCode: '+353' },
  { value: 'IM', label: 'Isle of Man', dialCode: '+44' },
  { value: 'IL', label: 'Israel', dialCode: '+972' },
  { value: 'IT', label: 'Italy', dialCode: '+39' },
  { value: 'JM', label: 'Jamaica', dialCode: '+1876' },
  { value: 'JP', label: 'Japan', dialCode: '+81' },
  { value: 'JE', label: 'Jersey', dialCode: '+44' },
  { value: 'JO', label: 'Jordan', dialCode: '+962' },
  { value: 'KZ', label: 'Kazakhstan', dialCode: '+7' },
  { value: 'KE', label: 'Kenya', dialCode: '+254' },
  { value: 'KI', label: 'Kiribati', dialCode: '+686' },
  { value: 'KP', label: 'North Korea', dialCode: '+850' },
  { value: 'KR', label: 'South Korea', dialCode: '+82' },
  { value: 'KW', label: 'Kuwait', dialCode: '+965' },
  { value: 'KG', label: 'Kyrgyzstan', dialCode: '+996' },
  { value: 'LA', label: 'Laos', dialCode: '+856' },
  { value: 'LV', label: 'Latvia', dialCode: '+371' },
  { value: 'LB', label: 'Lebanon', dialCode: '+961' },
  { value: 'LS', label: 'Lesotho', dialCode: '+266' },
  { value: 'LR', label: 'Liberia', dialCode: '+231' },
  { value: 'LY', label: 'Libya', dialCode: '+218' },
  { value: 'LI', label: 'Liechtenstein', dialCode: '+423' },
  { value: 'LT', label: 'Lithuania', dialCode: '+370' },
  { value: 'LU', label: 'Luxembourg', dialCode: '+352' },
  { value: 'MO', label: 'Macao', dialCode: '+853' },
  { value: 'MK', label: 'North Macedonia', dialCode: '+389' },
  { value: 'MG', label: 'Madagascar', dialCode: '+261' },
  { value: 'MW', label: 'Malawi', dialCode: '+265' },
  { value: 'MY', label: 'Malaysia', dialCode: '+60' },
  { value: 'MV', label: 'Maldives', dialCode: '+960' },
  { value: 'ML', label: 'Mali', dialCode: '+223' },
  { value: 'MT', label: 'Malta', dialCode: '+356' },
  { value: 'MH', label: 'Marshall Islands', dialCode: '+692' },
  { value: 'MQ', label: 'Martinique', dialCode: '+596' },
  { value: 'MR', label: 'Mauritania', dialCode: '+222' },
  { value: 'MU', label: 'Mauritius', dialCode: '+230' },
  { value: 'YT', label: 'Mayotte', dialCode: '+262' },
  { value: 'MX', label: 'Mexico', dialCode: '+52' },
  { value: 'FM', label: 'Micronesia', dialCode: '+691' },
  { value: 'MD', label: 'Moldova', dialCode: '+373' },
  { value: 'MC', label: 'Monaco', dialCode: '+377' },
  { value: 'MN', label: 'Mongolia', dialCode: '+976' },
  { value: 'ME', label: 'Montenegro', dialCode: '+382' },
  { value: 'MS', label: 'Montserrat', dialCode: '+1664' },
  { value: 'MA', label: 'Morocco', dialCode: '+212' },
  { value: 'MZ', label: 'Mozambique', dialCode: '+258' },
  { value: 'MM', label: 'Myanmar (Burma)', dialCode: '+95' },
  { value: 'NA', label: 'Namibia', dialCode: '+264' },
  { value: 'NR', label: 'Nauru', dialCode: '+674' },
  { value: 'NP', label: 'Nepal', dialCode: '+977' },
  { value: 'NL', label: 'Netherlands', dialCode: '+31' },
  { value: 'NC', label: 'New Caledonia', dialCode: '+687' },
  { value: 'NZ', label: 'New Zealand', dialCode: '+64' },
  { value: 'NI', label: 'Nicaragua', dialCode: '+505' },
  { value: 'NE', label: 'Niger', dialCode: '+227' },
  { value: 'NG', label: 'Nigeria', dialCode: '+234' },
  { value: 'NU', label: 'Niue', dialCode: '+683' },
  { value: 'NF', label: 'Norfolk Island', dialCode: '+672' },
  { value: 'MP', label: 'Northern Mariana Islands', dialCode: '+1670' },
  { value: 'NO', label: 'Norway', dialCode: '+47' },
  { value: 'OM', label: 'Oman', dialCode: '+968' },
  { value: 'PK', label: 'Pakistan', dialCode: '+92' },
  { value: 'PW', label: 'Palau', dialCode: '+680' },
  { value: 'PS', label: 'Palestine', dialCode: '+970' },
  { value: 'PA', label: 'Panama', dialCode: '+507' },
  { value: 'PG', label: 'Papua New Guinea', dialCode: '+675' },
  { value: 'PY', label: 'Paraguay', dialCode: '+595' },
  { value: 'PE', label: 'Peru', dialCode: '+51' },
  { value: 'PH', label: 'Philippines', dialCode: '+63' },
  { value: 'PN', label: 'Pitcairn Islands', dialCode: '+64' },
  { value: 'PL', label: 'Poland', dialCode: '+48' },
  { value: 'PT', label: 'Portugal', dialCode: '+351' },
  { value: 'PR', label: 'Puerto Rico', dialCode: '+1' },
  { value: 'QA', label: 'Qatar', dialCode: '+974' },
  { value: 'RE', label: 'Réunion', dialCode: '+262' },
  { value: 'RO', label: 'Romania', dialCode: '+40' },
  { value: 'RU', label: 'Russia', dialCode: '+7' },
  { value: 'RW', label: 'Rwanda', dialCode: '+250' },
  { value: 'BL', label: 'St. Barthélemy', dialCode: '+590' },
  { value: 'SH', label: 'St. Helena', dialCode: '+290' },
  { value: 'KN', label: 'St. Kitts & Nevis', dialCode: '+1869' },
  { value: 'LC', label: 'St. Lucia', dialCode: '+1758' },
  { value: 'MF', label: 'St. Martin', dialCode: '+590' },
  { value: 'PM', label: 'St. Pierre & Miquelon', dialCode: '+508' },
  { value: 'VC', label: 'St. Vincent & Grenadines', dialCode: '+1784' },
  { value: 'WS', label: 'Samoa', dialCode: '+685' },
  { value: 'SM', label: 'San Marino', dialCode: '+378' },
  { value: 'ST', label: 'São Tomé & Príncipe', dialCode: '+239' },
  { value: 'SA', label: 'Saudi Arabia', dialCode: '+966' },
  { value: 'SN', label: 'Senegal', dialCode: '+221' },
  { value: 'RS', label: 'Serbia', dialCode: '+381' },
  { value: 'SC', label: 'Seychelles', dialCode: '+248' },
  { value: 'SL', label: 'Sierra Leone', dialCode: '+232' },
  { value: 'SG', label: 'Singapore', dialCode: '+65' },
  { value: 'SX', label: 'Sint Maarten', dialCode: '+1721' },
  { value: 'SK', label: 'Slovakia', dialCode: '+421' },
  { value: 'SI', label: 'Slovenia', dialCode: '+386' },
  { value: 'SB', label: 'Solomon Islands', dialCode: '+677' },
  { value: 'SO', label: 'Somalia', dialCode: '+252' },
  { value: 'ZA', label: 'South Africa', dialCode: '+27' },
  { value: 'GS', label: 'South Georgia & South Sandwich Islands', dialCode: '+500' },
  { value: 'SS', label: 'South Sudan', dialCode: '+211' },
  { value: 'ES', label: 'Spain', dialCode: '+34' },
  { value: 'LK', label: 'Sri Lanka', dialCode: '+94' },
  { value: 'SD', label: 'Sudan', dialCode: '+249' },
  { value: 'SR', label: 'Suriname', dialCode: '+597' },
  { value: 'SJ', label: 'Svalbard & Jan Mayen', dialCode: '+47' },
  { value: 'SZ', label: 'Eswatini', dialCode: '+268' },
  { value: 'SE', label: 'Sweden', dialCode: '+46' },
  { value: 'CH', label: 'Switzerland', dialCode: '+41' },
  { value: 'SY', label: 'Syria', dialCode: '+963' },
  { value: 'TW', label: 'Taiwan', dialCode: '+886' },
  { value: 'TJ', label: 'Tajikistan', dialCode: '+992' },
  { value: 'TZ', label: 'Tanzania', dialCode: '+255' },
  { value: 'TH', label: 'Thailand', dialCode: '+66' },
  { value: 'TL', label: 'Timor-Leste', dialCode: '+670' },
  { value: 'TG', label: 'Togo', dialCode: '+228' },
  { value: 'TK', label: 'Tokelau', dialCode: '+690' },
  { value: 'TO', label: 'Tonga', dialCode: '+676' },
  { value: 'TT', label: 'Trinidad & Tobago', dialCode: '+1868' },
  { value: 'TN', label: 'Tunisia', dialCode: '+216' },
  { value: 'TR', label: 'Turkey', dialCode: '+90' },
  { value: 'TM', label: 'Turkmenistan', dialCode: '+993' },
  { value: 'TC', label: 'Turks & Caicos Islands', dialCode: '+1649' },
  { value: 'TV', label: 'Tuvalu', dialCode: '+688' },
  { value: 'UG', label: 'Uganda', dialCode: '+256' },
  { value: 'UA', label: 'Ukraine', dialCode: '+380' },
  { value: 'AE', label: 'United Arab Emirates', dialCode: '+971' },
  { value: 'GB', label: 'United Kingdom', dialCode: '+44' },
  { value: 'UK', label: 'United Kingdom', dialCode: '+44' },
  { value: 'US', label: 'United States', dialCode: '+1' },
  { value: 'UM', label: 'U.S. Outlying Islands', dialCode: '+1' },
  { value: 'UY', label: 'Uruguay', dialCode: '+598' },
  { value: 'UZ', label: 'Uzbekistan', dialCode: '+998' },
  { value: 'VU', label: 'Vanuatu', dialCode: '+678' },
  { value: 'VE', label: 'Venezuela', dialCode: '+58' },
  { value: 'VN', label: 'Vietnam', dialCode: '+84' },
  { value: 'VG', label: 'British Virgin Islands', dialCode: '+1284' },
  { value: 'VI', label: 'U.S. Virgin Islands', dialCode: '+1340' },
  { value: 'WF', label: 'Wallis & Futuna', dialCode: '+681' },
  { value: 'EH', label: 'Western Sahara', dialCode: '+212' },
  { value: 'YE', label: 'Yemen', dialCode: '+967' },
  { value: 'ZM', label: 'Zambia', dialCode: '+260' },
  { value: 'ZW', label: 'Zimbabwe', dialCode: '+263' },
];
