import type { ColDef } from '@ag-grid-community/core';
import type { ReportMetadata } from '@/hooks/use-reports';

export const reportColumnDefs: ColDef<ReportMetadata>[] = [
  {
    field: 'name',
    headerName: 'Report Name',
    flex: 1,
    sortable: true,
    filter: true,
    sort: 'asc',
    sortIndex: 0,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 2,
    sortable: true,
    filter: true,
  },
];
