import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import FormInput from '@/components/form-components/form-input';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Switch } from '@/components/ui/switch';
import type { Contact } from '@/hooks/use-contacts';
import { countries } from '@/pages/app/domains/data/countries';

const contactSchema = z.object({
  legal_name: z.string().min(1, 'RDDS Name is required'),
  email: z.string().email('Invalid email address'),
  phone_number: z.string().min(1, 'Company Phone number is required'),
  alt_phone: z.string().nullable().optional(),
  fax_number: z.string().nullable().optional(),
  address_line_1: z.string().min(1, 'Address is required'),
  address_line_2: z.string().nullable().optional(),
  address_line_3: z.string().nullable().optional(),
  city: z.string().min(1, 'City is required'),
  state_province: z.string().nullable().optional(),
  postal_code: z.string().min(1, 'Postal code is required'),
  country: z.string().min(1, 'Country is required'),
  alt_name: z.string().nullable().optional(),
  alt_email: z.string().nullable().optional(),
  title: z.string().nullable().optional(),
  role_name: z.string().nullable().optional(),
  organization_legal_name: z.string().nullable(),
  c_suite: z.boolean().optional(),
  sole_prop: z.boolean().optional(),
  new_legal_name: z.boolean().optional(),
  domain_associations: z.array(z.object({
    domain: z.string().min(1, 'Domain is required'),
    type: z.enum(['registrant', 'verification'], {
      required_error: 'Contact type is required',
    }),
  })).optional(),
});

type ContactFormData = z.infer<typeof contactSchema>;

type ContactSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialData?: Contact;
  onSubmit: (data: ContactFormData) => Promise<void>;
  availableDomains?: string[];
};

export function ContactSheet({ open, onOpenChange, initialData, onSubmit, availableDomains }: ContactSheetProps) {
  const form = useForm<ContactFormData>({
    resolver: zodResolver(contactSchema),
    defaultValues: {
      legal_name: '',
      email: '',
      phone_number: '',
      alt_phone: null,
      fax_number: null,
      address_line_1: '',
      address_line_2: null,
      address_line_3: null,
      city: '',
      state_province: '',
      postal_code: '',
      country: '',
      alt_name: null,
      alt_email: null,
      title: null,
      role_name: null,
      organization_legal_name: null,
      c_suite: false,
      sole_prop: false,
      new_legal_name: false,
      domain_associations: [{
        domain: availableDomains?.[0] || '',
        type: 'registrant',
      }],
    },
  });

  // Update form values only when the sheet is opened and initialData changes.
  useEffect(() => {
    if (!open)
      return;

    if (initialData) {
      const formData = {
        legal_name: initialData.legal_name,
        email: initialData.email,
        phone_number: initialData.phone_number,
        alt_phone: initialData.alt_phone,
        fax_number: initialData.fax_number,
        address_line_1: initialData.address_line_1,
        address_line_2: initialData.address_line_2,
        address_line_3: initialData.address_line_3,
        city: initialData.city,
        state_province: initialData.state_province,
        postal_code: initialData.postal_code,
        country: initialData.country,
        alt_name: initialData.alt_name,
        alt_email: initialData.alt_email,
        title: initialData.title,
        role_name: initialData.role_name,
        organization_legal_name: initialData.organization_legal_name,
        c_suite: initialData.c_suite,
        sole_prop: initialData.sole_prop,
        new_legal_name: initialData.new_legal_name,
        domain_associations: initialData.domain_contact_junctions?.map((j) => ({
          domain: j.domain,
          type: j.type,
        })) || [{
          domain: availableDomains?.[0] || '',
          type: 'registrant',
        }],
      };
      form.reset(formData);
    } else {
      form.reset({
        legal_name: '',
        email: '',
        phone_number: '',
        alt_phone: null,
        fax_number: null,
        address_line_1: '',
        address_line_2: null,
        address_line_3: null,
        city: '',
        state_province: '',
        postal_code: '',
        country: '',
        alt_name: null,
        alt_email: null,
        title: null,
        role_name: null,
        organization_legal_name: null,
        c_suite: false,
        sole_prop: false,
        new_legal_name: false,
        domain_associations: [{
          domain: availableDomains?.[0] || '',
          type: 'registrant',
        }],
      });
    }
  }, [open, initialData]);

  const handleFormSubmit = async (data: ContactFormData) => {
    await onSubmit(data);
    form.reset();
    onOpenChange(false);
  };

  const handleAddDomain = () => {
    const currentDomains = form.getValues('domain_associations') || [];
    form.setValue('domain_associations', [
      ...currentDomains,
      { domain: '', type: 'registrant' },
    ]);
  };

  const handleRemoveDomain = (index: number) => {
    const currentDomains = form.getValues('domain_associations') || [];
    form.setValue('domain_associations', currentDomains.filter((_, i) => i !== index));
  };

  const domainAssociations = form.watch('domain_associations') || [];

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>{initialData ? 'Edit Contact' : 'New Contact'}</SheetTitle>
        </SheetHeader>
        <form onSubmit={form.handleSubmit(handleFormSubmit)} className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto px-1 pt-8">
          <FormInput
            control={form.control}
            name="legal_name"
            label="RDDS Name"
            required
            rules={{ required: 'RDDS Name is required' }}
          />

          <FormInput
            control={form.control}
            name="email"
            label="Email"
            required
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[\w.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
          />

          <FormInput
            control={form.control}
            name="phone_number"
            label="Company Phone"
            required
            rules={{ required: 'Company Phone number is required' }}
          />

          <FormInput
            control={form.control}
            name="alt_phone"
            label="Direct Phone"
          />

          <FormInput
            control={form.control}
            name="fax_number"
            label="Fax Number"
          />

          <FormInput
            control={form.control}
            name="address_line_1"
            label="Address Line 1"
            required
            rules={{ required: 'Address is required' }}
          />

          <FormInput
            control={form.control}
            name="address_line_2"
            label="Address Line 2"
          />

          <FormInput
            control={form.control}
            name="address_line_3"
            label="Address Line 3"
          />

          <div className="grid grid-cols-2 gap-4">
            <FormInput
              control={form.control}
              name="city"
              label="City"
              required
              rules={{ required: 'City is required' }}
            />

            <FormInput
              control={form.control}
              name="state_province"
              label="State/Province"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <FormInput
              control={form.control}
              name="postal_code"
              label="Postal Code"
              required
              rules={{ required: 'Postal code is required' }}
            />

            <Controller
              control={form.control}
              name="country"
              rules={{ required: 'Country is required' }}
              render={({ field, fieldState: { error } }) => (
                <div className="space-y-1">
                  <Label htmlFor="country">Country*</Label>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select country" />
                    </SelectTrigger>
                    <SelectContent>
                      {countries.map((country) => (
                        <SelectItem key={country.value} value={country.value}>
                          {country.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {error && <p className="text-sm text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>

          <FormInput
            control={form.control}
            name="alt_name"
            label="Alternate Name"
          />

          <FormInput
            control={form.control}
            name="alt_email"
            label="Alternate Email"
          />

          <FormInput
            control={form.control}
            name="title"
            label="Title"
          />

          <FormInput
            control={form.control}
            name="role_name"
            label="Role"
          />

          <FormInput
            control={form.control}
            name="organization_legal_name"
            label="Organization Legal Name"
          />

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Label htmlFor="c_suite">C-Suite</Label>
              <Switch
                id="c_suite"
                checked={form.watch('c_suite')}
                onCheckedChange={(checked) => form.setValue('c_suite', checked)}
              />
            </div>

            <div className="flex items-center justify-between">
              <Label htmlFor="sole_prop">Sole Proprietorship</Label>
              <Switch
                id="sole_prop"
                checked={form.watch('sole_prop')}
                onCheckedChange={(checked) => form.setValue('sole_prop', checked)}
              />
            </div>

            <div className="flex items-center justify-between">
              <Label htmlFor="new_legal_name">New Legal Name</Label>
              <Switch
                id="new_legal_name"
                checked={form.watch('new_legal_name')}
                onCheckedChange={(checked) => form.setValue('new_legal_name', checked)}
              />
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">Domain Association</h3>
            </div>
            <div className="grid grid-cols-[1fr] gap-2">
              {/* Display domain name */}
              <div className="text-sm text-muted-foreground">
                Domain:
                {' '}
                {availableDomains?.[0]}
              </div>
              {/* Contact Type Selection */}
              <div>
                <Label>Contact Type</Label>
                <Select
                  value={form.watch('domain_associations.0.type')}
                  onValueChange={(value) => {
                    // Update both type and domain
                    form.setValue('domain_associations', [{
                      domain: availableDomains?.[0] || '',
                      type: value as 'registrant' | 'verification',
                    }]);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="registrant">Registrant</SelectItem>
                    <SelectItem value="verification">Verification</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          <div className="pt-4">
            <Button type="submit" className="w-full" disabled={form.formState.isSubmitting}>
              {initialData ? 'Update Contact' : 'Create Contact'}
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}
